//Libraries
import React, { createContext } from "react";
import app from "firebase/app";

//Config
import { FIREBASE_CONFIG } from "../config.json";

var fireapp = app.initializeApp(FIREBASE_CONFIG);

const FirebaseContext = createContext(null);

export { FirebaseContext };

export default ({ children }) => {
  if (!app.apps.length) {
    fireapp = app.initializeApp({ FIREBASE_CONFIG });
  }
  return <FirebaseContext.Provider value={fireapp}>{children}</FirebaseContext.Provider>;
};
