import React, { useState } from "react";

//Jsons
import faqs_json from "../assets/json/Help/raat/faqs.json";

const Help = () => {
  const [faqs] = useState(faqs_json);

  return (
    <main className="flex-1 overflow-y-auto">
      <div className="pt-8 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/*<h1 className="mb-8 flex-1 text-2xl font-bold text-gray-700">Ayuda</h1>*/}

        {/* Questions */}
        <div className="bg-white">
          <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
            <div className="max-w-2xl lg:mx-auto lg:text-center">
              <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">Preguntas Frecuentes</h2>
              <p className="mt-4 text-gray-500">Se ofrece aquí respuesta a una selección de las preguntas más frecuentes planteadas por los usuarios de la Plataforma.</p>
            </div>
            <div className="mt-20">
              <dl className="space-y-10 lg:space-y-0 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:gap-y-10">
                {faqs.map((faq) => (
                  <div key={faq.id}>
                    <dt className="font-semibold text-gray-900">{faq.question}</dt>
                    <dd className="mt-3 text-gray-500">{faq.answer}</dd>
                    {faq.bullets &&
                      faq.bullets.map((bullet) => (
                        <dd key={bullet.text} className="mt-2 ml-4 text-gray-500">
                          • {bullet.text}{" "}
                          <a href={bullet.url} className="text-red-600 font-medium hover:text-red-500 cursor-pointer" target="_blank" rel="noopener noreferrer">
                            {bullet.url_text}
                          </a>
                        </dd>
                      ))}
                    {faq.url ? (
                      <dd className="mt-3 text-gray-500">
                        <a href={faq.url.value || "#"} className="text-red-600 font-bold hover:text-red-500 cursor-pointer" target="_blank" rel="noopener noreferrer">
                          {faq.url.text}
                        </a>
                      </dd>
                    ) : (
                      ""
                    )}
                  </div>
                ))}
              </dl>
            </div>
          </div>
        </div>

        {/* Powered by */}
        <div className="mt-12 border-gray-200 py-8">
          <p className="text-base text-gray-400 text-center">&copy; 2023 OXXO, NOVA 2023.3.2 👨‍💻 Develop by team Mi OXXO.</p>
        </div>
      </div>
    </main>
  );
};

export default Help;
