//Libraries
import React, { useState, useEffect, useContext } from "react";
import { XCircleIcon } from "@heroicons/react/solid";

//Components
import ForgotPassword from "../Modals/ForgotPassword";

//Custom Hooks
import useInput from "../../hooks/use-input";

//Services
import { classNames } from "../../services/tailwindHelpers";
import { login, getUserById } from "../../services/firebaseService";
import { FirebaseContext } from "../../utils/firebase";

//Assets
import bg_photo from "../../assets/img/login_background.jpg";
import logo from "../../assets/img/raat/logo_short_color.png";
import logo_full from "../../assets/img/raat/logo_full.png";

const Login = ({ setProfile }) => {
  const firebase = useContext(FirebaseContext);

  //const { value: email, hasError: emailHasError, valueChangeHandler: emailChangeHandler, inputBlurHandler: emailBlurHandler, reset: emailReset } = useInput((value) => value.trim() !== "" && /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value.trim()));
  const { value: email, hasError: emailHasError, valueChangeHandler: emailChangeHandler, inputBlurHandler: emailBlurHandler, reset: emailReset } = useInput((value) => value.trim() !== "");
  const { value: password, hasError: passwordHasError, valueChangeHandler: passwordChangeHandler, inputBlurHandler: passwordBlurHandler, reset: passwordReset } = useInput((value) => value.trim() !== "");

  const [errorMessage, setErrorMessage] = useState("");
  const [openForgotPassword, setOpenForgotPassword] = useState(false);

  useEffect(() => {
    handleAuthListener();
  }, []);

  const handleLogin = async (event) => {
    event.preventDefault();

    let not_email = email + "@eventosfemco.com";
    let password_fix = password.length <= 5 ? `${password}_rati` : password;

    let res = await login(not_email, password_fix);

    setErrorMessage(res.message);

    emailReset();
    passwordReset();
  };

  const handleAuthListener = async () => {
    await firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        let usr = await getUserById(user.uid);

        usr["za"] = user.za;

        setProfile(usr);
      } else {
        setProfile({
          uid: "",
          name: "",
          last: "",
          email: "",
          theme: "",
          url_photo: "",
          za: "",
        });
      }
    });
  };

  const handleCheck = () => {};

  return (
    <div className="min-h-screen bg-white flex">
      <div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
        <div className="mx-auto w-full max-w-sm lg:w-96">
          <div>
            <img className="h-12 w-auto" src={logo} alt="Logo Rati 2021" />
            <h2 className="mt-6 text-3xl font-bold text-gray-900">Iniciar sesión</h2>
            <p className="mt-2 text-sm text-gray-600">Inicia sesión con el acceso proporcionado via email.</p>

            <div className={(errorMessage ? "" : "hidden") + " my-8 rounded-md bg-red-50 p-4"}>
              <div className="flex">
                <div className="flex-shrink-0">
                  <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                </div>
                <div className="ml-3">
                  <h3 className="text-sm font-medium text-red-800">Error</h3>
                  <div className="mt-2 text-sm text-red-700">
                    <p>{errorMessage}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-8">
            <div className="mt-6">
              <form action="#" method="POST" className="space-y-6" onSubmit={(event) => handleLogin(event)}>
                <div>
                  <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                    Numero Empleado
                  </label>
                  <div className="mt-1">
                    <input
                      id="email"
                      name="email"
                      type="text"
                      className={classNames(emailHasError ? "border-red-300" : "border-gray-300", "appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm")}
                      value={email}
                      onChange={emailChangeHandler}
                      onBlur={emailBlurHandler}
                      autoComplete="email"
                      required
                    />
                    {emailHasError && (
                      <p className="mt-2 text-sm text-red-600" id="email-error">
                        Favor de introducir un correo valido.
                      </p>
                    )}
                  </div>
                </div>

                <div className="space-y-1">
                  <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                    Contraseña
                  </label>
                  <div className="mt-1">
                    <input
                      id="password"
                      name="password"
                      type="password"
                      autoComplete="current-password"
                      className={classNames(passwordHasError ? "border-red-300" : "border-gray-300", "appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm")}
                      value={password}
                      onChange={passwordChangeHandler}
                      onBlur={passwordBlurHandler}
                      required
                    />
                    {passwordHasError && (
                      <p className="mt-2 text-sm text-red-600" id="email-error">
                        Favor de introducir tu contraseña.
                      </p>
                    )}
                  </div>
                </div>

                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <input id="remember_me" name="remember_me" type="checkbox" className="h-4 w-4 text-red-600 focus:ring-red-500 border-gray-300 rounded" onChange={() => handleCheck} checked={true} />
                    <label htmlFor="remember_me" className="ml-2 block text-sm text-gray-900">
                      Recordarme
                    </label>
                  </div>

                  <div className="text-sm">
                    <div onClick={() => setOpenForgotPassword(true)} className="font-medium text-blue-600 hover:text-blue-500 cursor-pointer">
                      ¿Olvidaste tu contraseña?
                    </div>
                  </div>
                </div>

                <div>
                  <button type="submit" className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700">
                    Entrar
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="hidden lg:block relative w-0 flex-1">
        <img className="absolute inset-0 h-full w-full object-cover" src={bg_photo} alt="" />
        <img className="relative w-3/4 h-auto mx-auto my-72 align-middle xl:w-1/2 xl:my-60" src={logo_full} alt="" />
      </div>

      <ForgotPassword open={openForgotPassword} setOpen={setOpenForgotPassword} />
    </div>
  );
};

export default Login;
