//Libraries
import React from "react";

const Iframe = ({ profile, height, type, url }) => {
  const iframe = getIFrameType(type, url, profile, height);

  return <div dangerouslySetInnerHTML={{ __html: iframe ? iframe : "" }} />;
};
export default Iframe;

function getIFrameType(type, url, profile, height) {
  switch (type) {
    case "website":
      url = `${url}?userID=${profile.uid}`;

      return `<iframe width="100%" height="${height ? height : 500}" src="${url || ""}" title="RATI Misiones"></iframe>`;

    case "video":
      return `<video width="100%" height="${height ? height : 500}" controls autoplay><source src="${url || ""}" type="video/mp4">Your browser does not support the video tag.</video>`;

    case "vimeo":
    default:
      return `<iframe width="100%" height="${height ? height : 500}" src="${url || ""}" frameborder="0" allow="fullscreen; picture-in-picture" allowfullscreen webkitallowfullscreen mozallowfullscreen title="RATI Streaming"></iframe>`;
  }
}
