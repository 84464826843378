//Libraries
import React, { Fragment, useState } from "react";
import { Transition, Listbox } from "@headlessui/react";
import { ChevronDoubleDownIcon, VideoCameraIcon, CheckIcon, SelectorIcon } from "@heroicons/react/solid";

//Components
import Iframe from "./Iframe";

//Services
import { classNames } from "../services/tailwindHelpers";
import channels from "../assets/json/Live/channels.json";

const FloatingVideo = ({ profile, open, setOpen }) => {
  const [selectedChannel, setSelectedChannel] = useState(channels[0]);

  const handleSelectChannel = (event) => {
    setSelectedChannel(event);
  };

  return (
    <div className={classNames(open ? "bg-gray-100" : "bg-blue-600 hidden", "absolute bottom-0 right-8 py-4 px-4 rounded-t-lg md:block")}>
      {open ? (
        <div className="flex justify-between">
          <ChevronDoubleDownIcon className="flex-shrink-0 h-5 w-5 text-gray-600 right-0 cursor-pointer" aria-hidden="true" onClick={() => setOpen(!open)} />

          <Listbox value={selectedChannel} onChange={handleSelectChannel}>
            {({ open }) => (
              <>
                <div className="relative">
                  <Listbox.Button className="bg-white relative w-full mb-2 border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 sm:text-sm">
                    <span className="block truncate">{selectedChannel.name}</span>
                    <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                      <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                    </span>
                  </Listbox.Button>

                  <Transition show={open} as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
                    <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                      {channels.map((channel) => (
                        <Listbox.Option key={channel.id} className={({ active }) => classNames(active ? "text-white bg-blue-600" : "text-gray-900", "cursor-default select-none relative py-2 pl-3 pr-9")} value={channel}>
                          {({ selectedChannel, active }) => (
                            <>
                              <span className={classNames(selectedChannel ? "font-semibold" : "font-normal", "block truncate")}>{channel.name}</span>

                              {selectedChannel ? (
                                <span className={classNames(active ? "text-white" : "text-blue-600", "absolute inset-y-0 right-0 flex items-center pr-4")}>
                                  <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                </span>
                              ) : null}
                            </>
                          )}
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </Transition>
                </div>
              </>
            )}
          </Listbox>
        </div>
      ) : (
        <VideoCameraIcon className="animate-bounce flex-shrink-0 h-5 w-5 text-white right-0 cursor-pointer" aria-hidden="true" onClick={() => setOpen(!open)} />
      )}

      {open && (
        <div>
          <Iframe profile={profile} height={"200"} type={selectedChannel.type} url={selectedChannel.url} allow="autoplay"></Iframe>
        </div>
      )}
    </div>
  );
};

export default FloatingVideo;
