// Libraries
import React, { useEffect, useState } from "react";

//Services
import { classNames } from "../../services/tailwindHelpers";
import { getReactions, reactionsPost } from "../../services/firebaseService";

function RatiReactions({ profile }) {
  return (
    <div className="border-bg">
      <div className="">{profile ? <ViewReactions profile={profile} /> : ""}</div>
    </div>
  );
}

function ViewReactions() {
  const [reactions, setReactions] = useState({});
  const handleReactionPost = async (reaction) => {
    if (!reaction) return;

    await reactionsPost(reaction);
    const res = await getReactions();
    setReactions(res);
  };

  useEffect(() => {
    async function fetchData() {
      const res = await getReactions();
      setReactions(res);
    }
    fetchData();
  }, []);

  return (
    <div>
      <div id="emojis-content" className="-mt-4 -ml-6 lg:mt-2 lg:ml-4">
        <span className="inline-block relative">
          <span className="emoji emoji-thumbsup select-none text-3xl cursor-pointer hover:text-lg" onClick={() => handleReactionPost("thumbsup")}></span>
          {reactions["thumbsup"] ? (
            <span className="absolute top-0 right-0 block h-6 w-6 transform -translate-y-1/2 translate-x-1/2 rounded-full ring-2 ring-white bg-blue-500 text-center">
              <p className={classNames(reactions && reactions["thumbsup"] > 99 ? "-m-2" : "-m-1", "absolute select-none my-1 text-white text-xs font-bold")}>{reactions["thumbsup"]}</p>
            </span>
          ) : (
            ""
          )}
        </span>
        <span className="inline-block relative">
          <span className="emoji emoji-clap select-none text-3xl cursor-pointer" onClick={() => handleReactionPost("clap")}></span>
          {reactions["clap"] ? (
            <span className="absolute top-0 right-0 block h-6 w-6 transform -translate-y-1/2 translate-x-1/2 rounded-full ring-2 ring-white bg-blue-500 text-center">
              <p className={classNames(reactions && reactions["clap"] > 99 ? "-m-2" : "-m-1", "absolute select-none my-1 text-white text-xs font-bold")}>{reactions["clap"]}</p>
            </span>
          ) : (
            ""
          )}
        </span>
        <span className="inline-block relative">
          <span className="emoji emoji-sunglasess select-none text-3xl cursor-pointer" onClick={() => handleReactionPost("sunglasess")}></span>
          {reactions["sunglasess"] ? (
            <span className="absolute top-0 right-0 block h-6 w-6 transform -translate-y-1/2 translate-x-1/2 rounded-full ring-2 ring-white bg-blue-500 text-center">
              <p className={classNames(reactions && reactions["sunglasess"] > 99 ? "-m-2" : "-m-1", "absolute select-none my-1 text-white text-xs font-bold")}>{reactions["sunglasess"]}</p>
            </span>
          ) : (
            ""
          )}
        </span>
        <span className="inline-block relative">
          <span className="emoji emoji-raised_hands select-none text-3xl cursor-pointer" onClick={() => handleReactionPost("raised_hands")}></span>
          {reactions["raised_hands"] ? (
            <span className="absolute top-0 right-0 block h-6 w-6 transform -translate-y-1/2 translate-x-1/2 rounded-full ring-2 ring-white bg-blue-500 text-center">
              <p className={classNames(reactions && reactions["raised_hands"] > 99 ? "-m-2" : "-m-1", "absolute select-none my-1 text-white text-xs font-bold")}>{reactions["raised_hands"]}</p>
            </span>
          ) : (
            ""
          )}
        </span>
        <span className="inline-block relative">
          <span className="emoji emoji-open_mount select-none text-3xl cursor-pointer" onClick={() => handleReactionPost("open_mount")}></span>
          {reactions["open_mount"] ? (
            <span className="absolute top-0 right-0 block h-6 w-6 transform -translate-y-1/2 translate-x-1/2 rounded-full ring-2 ring-white bg-blue-500 text-center">
              <p className={classNames(reactions && reactions["open_mount"] > 99 ? "-m-2" : "-m-1", "absolute select-none my-1 text-white text-xs font-bold")}>{reactions["open_mount"]}</p>
            </span>
          ) : (
            ""
          )}
        </span>
      </div>
    </div>
  );
}

export { RatiReactions };
