//Libraries
import axios from "axios";

//Config
import { API_URL_MISSIONS } from "../config.json";

export const completeChallenge = async (challenge_id, user_id, user_email) => {
  const data = {
    type: "challenges",
    userID: user_id,
    userMail: user_email,
    scID: challenge_id,
  };

  let res = await axios.post(`${API_URL_MISSIONS}/setChallenge`, data).catch((e) => {
    console.log("[missionsService.js][completeChallenge] Error: ", e);

    return [];
  });

  if (res.status !== 200) return false;

  return true;
};
