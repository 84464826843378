//Libraries
import React, { useState } from "react";

//Services
import documents_json from "../assets/json/Documents/documents.json";

const Documents = () => {
  const [documents] = useState(documents_json);

  return (
    <main className="flex-1 overflow-y-auto">
      <div className="pt-8 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h1 className="mb-8 flex-1 text-2xl font-bold text-gray-700">Documentos</h1>

        {/* Documents */}
        <div className="flex flex-col">
          <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
              <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Nombre
                      </th>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Área
                      </th>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Tipo
                      </th>
                      <th scope="col" className="relative px-6 py-3">
                        <span className="sr-only">Descargar</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {documents.map((document, documentIdx) => (
                      <tr key={document.id} className={documentIdx % 2 === 0 ? "bg-white" : "bg-gray-50"}>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{document.name}</td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{document.author}</td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">{document.type}</span>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                          <a href={document.link} className="text-blue-600 hover:text-blue-900" target="_blank" rel="noopener noreferrer">
                            {document.type === "URL" ? "Abrir" : "Descargar"}
                          </a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Documents;
