//Libraries
import React from "react";
import { useState } from "react";
import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/solid";

//Custom Hooks
import useInput from "../hooks/use-input";

//Services
import { classNames } from "../services/tailwindHelpers";
import { createQuestion } from "../services/firebaseService";
import { completeChallenge } from "../services/missionsService";

//Jsons
import topics_json from "../assets/json/Questions/raat/topics.json";

const Questions = ({ user, profile }) => {
  const [topics] = useState(topics_json);

  const [blockSubmitButton, setBlockSubmitButton] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showWarningAlert, setShowWarningAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);

  const { value: message, isValid: messageIsValid, hasError: messageHasError, valueChangeHandler: messageChangeHandler, inputBlurHandler: messageBlurHandler, reset: messageReset } = useInput((value) => value.trim() !== "");
  const { value: topic, isValid: topicIsValid, hasError: topicHasError, valueChangeHandler: topicChangeHandler, inputBlurHandler: topicBlurHandler, reset: topicReset } = useInput((value) => value.trim() !== "");

  let formIsValid = false;

  if (messageIsValid && topicIsValid) {
    formIsValid = true;
  }

  const formSubmissionHandler = async (event) => {
    event.preventDefault();
    setBlockSubmitButton(true);

    if (!formIsValid) {
      setShowWarningAlert(true);
      setBlockSubmitButton(false);

      return;
    }

    const response = await createQuestion(user, topic, message);

    if (!response) {
      setShowErrorAlert(true);
      setBlockSubmitButton(false);

      return;
    }

    setShowSuccessAlert(true);
    setShowErrorAlert(false);
    setShowWarningAlert(false);

    messageReset();
    topicReset();

    setBlockSubmitButton(false);

    //Send Challenge
    if (response) {
      await completeChallenge("-NTyoROxTsilVoooEEyD", profile.uid, profile.email);
    }
  };

  return (
    <main className="flex-1 overflow-y-auto">
      <div className="pt-8 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h1 className="mb-8 flex-1 text-2xl font-bold text-gray-700">Preguntas</h1>

        {/* Success Alert */}
        <div className={(showSuccessAlert ? "" : "hidden") + " my-8 rounded-md bg-green-50 p-4"}>
          <div className="flex">
            <div className="flex-shrink-0">
              <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
            </div>
            <div className="ml-3">
              <h3 className="text-sm font-medium text-green-800">Gracias</h3>
              <div className="mt-2 text-sm text-green-700">
                <p>Recibimos correctamente tu pregunta.</p>
              </div>
            </div>
          </div>
        </div>

        {/* Warning Alert */}
        <div className={(showWarningAlert ? "" : "hidden") + " my-8 rounded-md bg-yellow-50 p-4"}>
          <div className="flex">
            <div className="flex-shrink-0">
              <XCircleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
            </div>
            <div className="ml-3">
              <h3 className="text-sm font-medium text-yellow-800">Revisa los siguientes puntos</h3>
              <div className="mt-2 text-sm text-yellow-700">
                <ul className="list-disc pl-5 space-y-1">
                  <li>El campo de 'Pregunta' no puede ir vacío.</li>
                  <li>Debes seleccionar una categoría de la lista, si ninguna aplica selecciona 'Otro'.</li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        {/* Error Alert */}
        <div className={(showErrorAlert ? "" : "hidden") + " my-8 rounded-md bg-red-50 p-4"}>
          <div className="flex">
            <div className="flex-shrink-0">
              <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
            </div>
            <div className="ml-3">
              <h3 className="text-sm font-medium text-red-800">Disculpa</h3>
              <div className="mt-2 text-sm text-red-700">
                <ul className="list-disc pl-5 space-y-1">
                  <p>Algún error sucedió de nuestro lado, intenta más tarde.</p>
                </ul>
              </div>
            </div>
          </div>
        </div>

        {/* Send comment */}
        <div className="bg-white rounded-lg px-4 pt-5 pb-4 ">
          <form className="space-y-8 " onSubmit={(event) => formSubmissionHandler(event)}>
            <div>
              <label htmlFor="message" className="block text-sm font-medium text-gray-700">
                Pregunta
              </label>
              <div className="mt-1">
                <textarea
                  id="message"
                  name="message"
                  rows={4}
                  className={classNames(messageHasError ? "border-red-300" : "border-gray-300", "border py-3 px-4 block w-full shadow-sm focus:ring-blue-500 focus:border-blue-500 rounded-md")}
                  value={message}
                  onChange={messageChangeHandler}
                  onBlur={messageBlurHandler}
                />
              </div>
              {messageHasError && (
                <p className="mt-2 text-sm text-red-600" id="label-message">
                  Agrega una pregunta
                </p>
              )}
            </div>

            <div>
              <label htmlFor="topic" className="block text-sm font-medium text-gray-700">
                Categoría
              </label>
              <select
                id="topic"
                name="topic"
                className={classNames(topicHasError ? "border-red-300" : "border-gray-300", "mt-1 block w-full shadow-sm pl-3 pr-10 py-2 text-base border border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md")}
                value={topic}
                onChange={topicChangeHandler}
                onBlur={(event) => topicBlurHandler(event)}
              >
                <option value="" hidden>
                  Seleciona una categoría
                </option>
                {topics.map((topic) => (
                  <option key={topic.id} value={topic.name}>
                    {topic.name}
                  </option>
                ))}
              </select>
            </div>

            <button
              type="submit"
              className={classNames(
                blockSubmitButton ? "opacity-50" : "hover:bg-blue-700",
                "my-4 w-full inline-flex items-center justify-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-blue-600 lg:w-48  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 hover:bg-blue-700",
              )}
              disabled={blockSubmitButton}
            >
              {blockSubmitButton ? (
                <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
              ) : null}
              Enviar
            </button>
          </form>
        </div>
      </div>
    </main>
  );
};

export default Questions;
