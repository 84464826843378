//Libraries
import { useEffect, useState } from "react";
import { TrashIcon } from "@heroicons/react/solid";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

//Custom Hooks
import useInput from "../../hooks/use-input";

//Services
import { classNames } from "../../services/tailwindHelpers";
import { sendVaccine, getVaccines, deleteVaccine } from "../../services/profileService";

const Health = ({ user, profile }) => {
  const [blockSubmitButton, setBlockSubmitButton] = useState(false);

  const [vaccines, setVaccines] = useState([]);

  const { value: dose, isValid: doseIsValid, hasError: doseHasError, valueChangeHandler: doseChangeHandler, inputBlurHandler: doseBlurHandler, reset: doseReset } = useInput((value) => value.trim() !== "");
  const { value: brand, isValid: brandIsValid, hasError: brandHasError, valueChangeHandler: brandChangeHandler, inputBlurHandler: brandBlurHandler, reset: brandReset } = useInput((value) => value.trim() !== "");
  const { value: lot, isValid: lotIsValid, hasError: lotHasError, valueChangeHandler: lotChangeHandler, inputBlurHandler: lotBlurHandler, reset: lotReset } = useInput((value) => value.trim() !== "");
  const { value: location, isValid: locationIsValid, hasError: locationHasError, valueChangeHandler: locationChangeHandler, inputBlurHandler: locationBlurHandler, reset: locationReset } = useInput((value) => value.trim() !== "");
  const { value: applicationDate, isValid: applicationDateIsValid, hasError: applicationDateHasError, valueChangeHandler: applicationDateChangeHandler, inputBlurHandler: applicationDateBlurHandler, reset: applicationDateReset } = useInput((value) => value.trim() !== "");
  const [selectedFile, setSelectedFile] = useState(null);

  let formIsValid = false;

  if (doseIsValid && brandIsValid && lotIsValid && locationIsValid && applicationDateIsValid) {
    formIsValid = true;
  }

  useEffect(() => {
    async function fetchData() {
      const get_vaccines = await getVaccines(user.uid);

      setVaccines(get_vaccines && get_vaccines.length > 0 ? get_vaccines : []);
    }
    fetchData();
  }, []);

  const formSubmissionHandler = async (event) => {
    event.preventDefault();
    setBlockSubmitButton(true);

    if (!formIsValid) {
      setBlockSubmitButton(false);

      return;
    }

    const data = new FormData();

    data.append("userId", profile.uid);
    data.append("dose", dose);
    data.append("brand", brand);
    data.append("lot", lot);
    data.append("location", location);
    data.append("applicationDate", applicationDate);

    if (selectedFile) {
      data.append("image", selectedFile);
    }

    const response = await sendVaccine(data);

    if (!response) {
      setBlockSubmitButton(false);

      return;
    }

    const new_vaccine = {
      userId: profile.uid,
      dose: dose,
      brand: brand,
      lot: lot,
      location: location,
      applicationDate: applicationDate,
    };

    handleAddVaccine(response, new_vaccine);

    doseReset();
    brandReset();
    lotReset();
    locationReset();
    applicationDateReset();

    setBlockSubmitButton(false);
  };

  const handleFileInput = async (event) => {
    const file = event.target.files[0];

    if (file && file.size > 10485760) {
      setSelectedFile(null);

      return;
    }

    setSelectedFile(file);

    event.target.value = null;
  };

  const handleAddVaccine = (vaccine_id, new_vaccine) => {
    let currents = [...vaccines];

    currents.push({ id: vaccine_id, ...new_vaccine });

    setVaccines(currents);
  };

  return (
    <div className="pt-4">
      <div>
        <h3 className="text-lg leading-6 font-medium text-gray-900">Registro de vacunación</h3>
        <p className="mt-1 text-sm text-gray-500">Te solicitamos llenar la información correspondiente al esquema de vacunación</p>
      </div>
      <form className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-12" onSubmit={(event) => formSubmissionHandler(event)}>
        <div className="sm:col-span-4">
          <label htmlFor="dose" className="block text-sm font-medium text-gray-700">
            Dosis
          </label>
          <div className="mt-1">
            <select
              id="dose"
              name="dose"
              autoComplete="dose"
              className={classNames(doseHasError ? "border-red-300" : "border-gray-300", "appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm")}
              value={dose}
              onChange={doseChangeHandler}
              onBlur={doseBlurHandler}
            >
              <option value="" hidden>
                Seleciona la dosis
              </option>
              <option value="1° Dosis">1° Dosis</option>
              <option value="2° Dosis">2° Dosis</option>
              <option value="Refuerzo">Refuerzo</option>
            </select>
          </div>
        </div>

        <div className="sm:col-span-4">
          <label htmlFor="brand" className="block text-sm font-medium text-gray-700">
            Marca
          </label>
          <div className="mt-1">
            <select
              id="brand"
              name="brand"
              autoComplete="brand"
              className={classNames(brandHasError ? "border-red-300" : "border-gray-300", "appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm")}
              value={brand}
              onChange={brandChangeHandler}
              onBlur={brandBlurHandler}
            >
              <option value="" hidden>
                Seleciona la marca
              </option>
              <option>Pfizer</option>
              <option>AstraZeneca</option>
              <option>Moderna</option>
              <option>Johnson</option>
              <option>CanSino</option>
              <option>Sinovac</option>
              <option>Sputnik V</option>
              <option>Otra</option>
            </select>
          </div>
        </div>

        <div className="sm:col-span-4">
          <label htmlFor="lot" className="block text-sm font-medium text-gray-700">
            Lote
          </label>
          <div className="mt-1">
            <input
              type="text"
              name="lot"
              id="lot"
              autoComplete="lot"
              className={classNames(lotHasError ? "border-red-300" : "border-gray-300", "appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm")}
              value={lot}
              onChange={lotChangeHandler}
              onBlur={lotBlurHandler}
              placeholder="Introduce el lote"
            />
          </div>
        </div>

        <div className="sm:col-span-3">
          <label htmlFor="applicationDate" className="block text-sm font-medium text-gray-700">
            Fecha de aplicacion
          </label>
          <div className="mt-1">
            <input
              id="applicationDate"
              name="applicationDate"
              type="date"
              autoComplete="applicationDate"
              className={classNames(applicationDateHasError ? "border-red-300" : "border-gray-300", "appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm")}
              value={applicationDate}
              onChange={applicationDateChangeHandler}
              onBlur={applicationDateBlurHandler}
              max={dayjs().format("YYYY-MM-DD")}
            />
          </div>
        </div>

        <div className="sm:col-span-5">
          <label htmlFor="location" className="block text-sm font-medium text-gray-700">
            Lugar
          </label>
          <div className="mt-1">
            <input
              id="location"
              name="location"
              type="text"
              autoComplete="location"
              className={classNames(locationHasError ? "border-red-300" : "border-gray-300", "appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm")}
              value={location}
              onChange={locationChangeHandler}
              onBlur={locationBlurHandler}
              placeholder="Introduce la ciudad o establecimiento"
            />
          </div>
        </div>

        <div className="sm:col-span-4">
          <label htmlFor="photo" className="block text-sm font-medium text-gray-700">
            Comprobante
          </label>
          <div className="mt-1">
            <span className="sr-only">Choose profile photo</span>
            <input
              type="file"
              className={classNames(false ? "border-red-300" : "border-gray-300", "appearance-none block w-full px-3 py-1 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm")}
              accept="image/png, image/jpeg, image/jpg"
              onChange={() => handleFileInput}
            />
          </div>
        </div>

        <div className="sm:col-start-11 sm:col-span-2 flex flex-row-reverse">
          <button
            type="submit"
            className={classNames(
              blockSubmitButton || !formIsValid ? "opacity-50" : "hover:bg-blue-700",
              "w-full inline-flex items-end justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-blue-600 lg:w-36  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 hover:bg-blue-700",
            )}
            disabled={blockSubmitButton || !formIsValid}
          >
            {blockSubmitButton ? (
              <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
            ) : null}
            Enviar
          </button>
        </div>
      </form>

      {/* Doses */}
      <Vaccines profile={profile} vaccines={vaccines} setVaccines={setVaccines} />
    </div>
  );
};

function Vaccines({ profile, vaccines, setVaccines }) {
  //Init is Timezone dayjs
  dayjs.extend(utc);
  dayjs.extend(timezone);

  const handleDeleteDose = async (dose_id) => {
    let response = await deleteVaccine(profile.uid, dose_id);

    if (!response) return;

    let currents = [...vaccines];

    const index = currents.findIndex((i) => i.id === dose_id);

    if (index > -1) {
      currents.splice(index, 1);
    }

    setVaccines(currents);
  };

  return (
    <div className="w-full">
      <div className="mt-8">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                      Dosis
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Marca
                    </th>
                    <th scope="col" className="hidden sm:block px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Lote
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Fecha
                    </th>
                    <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                      <span className="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {vaccines.map((vaccine) => (
                    <tr key={vaccine.id}>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">{vaccine.dose}</td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{vaccine.brand}</td>
                      <td className="hidden sm:block whitespace-nowrap px-3 py-4 text-sm text-gray-500">{vaccine.lot}</td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{vaccine.applicationDate._seconds ? dayjs.unix(vaccine.applicationDate._seconds).tz("Etc/GMT").format("DD/MMM/YYYY") : dayjs(vaccine.applicationDate).tz("Etc/GMT").format("DD/MMM/YYYY")}</td>
                      <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                        <a href="#" className="text-gray-400 hover:text-red-600" onClick={() => handleDeleteDose(vaccine.id)}>
                          <TrashIcon className="w-5 h-5" aria-hidden="true" />
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Health;
