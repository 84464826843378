//Libraries
import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { PaperAirplaneIcon } from "@heroicons/react/solid";
import TimeAgo from "react-timeago";

//Custom Hooks
import useInput from "../../hooks/use-input";

//Services
import { classNames } from "../../services/tailwindHelpers";
import { createPostComment } from "../../services/firebaseService";
import { completeChallenge } from "../../services/missionsService";

export default function PostComments({ profile, postId, comments, open, setOpen }) {
  const { value: message, isValid: messageIsValid, hasError: messageHasError, valueChangeHandler: messageChangeHandler, inputBlurHandler: messageBlurHandler, reset: messageReset } = useInput((value) => value.trim() !== "");

  const [blockSubmitButton, setBlockSubmitButton] = useState(false);

  useEffect(() => {}, [comments]);

  const formSubmissionHandler = async (event) => {
    event.preventDefault();
    setBlockSubmitButton(true);

    if (!messageIsValid) {
      setBlockSubmitButton(false);
      return;
    }

    if (!postId && !profile) {
      setBlockSubmitButton(false);
      return;
    }

    const response = await createPostComment(postId, profile, message);

    if (!response || message === "") {
      setBlockSubmitButton(false);
      return;
    }

    const pushing_comment = {
      user: {
        fullname: `${profile.name} ${profile.last}`,
        url_photo: profile.url_photo,
        uid: profile.uid,
      },
      date: Date.now(),
      content: message,
    };

    comments.push(pushing_comment);

    setBlockSubmitButton(false);
    messageReset();

    //Send Challenge
    if (response) {
      await completeChallenge("-NTyoROuebDaTg-60huR", profile.uid, profile.email);
    }
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" static className="fixed inset-0 overflow-hidden" open={open} onClose={setOpen}>
        <div className="absolute inset-0 overflow-hidden">
          <Transition.Child as={Fragment} enter="ease-in-out duration-500" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in-out duration-500" leaveFrom="opacity-100" leaveTo="opacity-0">
            <Dialog.Overlay className="absolute inset-0  ml-28 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex">
            <Transition.Child as={Fragment} enter="transform transition ease-in-out duration-500 sm:duration-700" enterFrom="translate-x-full" enterTo="translate-x-0" leave="transform transition ease-in-out duration-500 sm:duration-700" leaveFrom="translate-x-0" leaveTo="translate-x-full">
              <div className="w-screen max-w-md">
                <div className="h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl">
                  <div className="min-h-0 flex-1 flex flex-col py-6 overflow-y-scroll">
                    <div className="px-4 pt-16 sm:px-6">
                      <div className="flex items-start justify-between">
                        <Dialog.Title className="text-lg font-medium text-gray-900">Comentarios</Dialog.Title>
                        <div className="ml-3 h-7 flex items-center">
                          <button className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500" onClick={() => setOpen(false)}>
                            <span className="sr-only">Close panel</span>
                            <XIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="mt-6 relative flex-1 px-4 sm:px-6">
                      {/* Users Comments */}
                      <div className="px-2 py-4 sm:px-1">
                        <ul className="space-y-4">
                          {comments &&
                            comments.length > 0 &&
                            comments.map((comment, commentId) => (
                              <li key={commentId}>
                                <div className="flex space-x-3">
                                  <div className="flex-shrink-0">
                                    <img className="h-10 w-10 rounded-full object-cover" src={comment.user.url_photo} alt="" />
                                  </div>
                                  <div>
                                    <div className="text-sm space-x-2">
                                      <a href="#" className="font-medium text-gray-900">
                                        {comment.user.fullname}
                                      </a>
                                      <span>&#183;</span>
                                      <span className="text-gray-500 font-medium">{comment.date && comment.date.seconds ? <TimeAgo date={new Date(comment.date.seconds * 1000)} /> : "now"}</span>
                                      <span className="text-gray-500 font-medium"></span>
                                    </div>
                                    <div className="mt-1 text-sm text-gray-700">
                                      <p>{comment.content}</p>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                  <form className="flex-shrink-0 px-4 py-4 flex justify-end space-x-4" onSubmit={(event) => formSubmissionHandler(event)}>
                    <input
                      type="text"
                      name="message"
                      id="message"
                      autoComplete="family-name"
                      className="block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-light-blue-500 focus:border-light-blue-500 sm:text-sm"
                      value={message}
                      onChange={messageChangeHandler}
                      onBlur={messageBlurHandler}
                    />

                    <button
                      type="submit"
                      className={classNames(blockSubmitButton ? "opacity-50 cursor-default" : "hover:bg-blue-600", "bg-blue-500 inline-flex items-center px-2 shadow-sm text-sm font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-light-blue-500")}
                      disabled={blockSubmitButton}
                    >
                      {blockSubmitButton ? (
                        <svg className="animate-spin mx-1 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                          <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                      ) : (
                        <PaperAirplaneIcon className="mx-1 h-5 w-5 text-white" aria-hidden="true" />
                      )}
                    </button>
                  </form>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
