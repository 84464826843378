//Libraries
import React, { useState } from "react";
import { Fragment, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";

//Custom Hooks
import useInput from "../../hooks/use-input";

//Services
import { classNames } from "../../services/tailwindHelpers";
import { createPost } from "../../services/firebaseService";
import { uploadImagePosts } from "../../services/postsService";
import { completeChallenge } from "../../services/missionsService";

const CreatePost = ({ profile, open, setOpen, setCreatedPost }) => {
  const cancelButtonRef = useRef(null);
  const submitButtonRef = useRef(null);

  const { value: message, isValid: messageIsValid, hasError: messageHasError, valueChangeHandler: messageChangeHandler, inputBlurHandler: messageBlurHandler, reset: messageReset } = useInput((value) => value.trim() !== "");

  const [selectedFile, setSelectedFile] = useState(null);
  const [imagePreview, setImagePreview] = useState("");
  const [validImage, setValidImage] = useState(null);
  const [blockSubmitButton, setBlockSubmitButton] = useState(false);

  const formSubmissionHandler = async (event) => {
    event.preventDefault();

    let challengeid = "-NTyoROvJduDZxz_Xz3J";

    if (!messageIsValid) {
      messageBlurHandler();
      return;
    }
    if (submitButtonRef.current) submitButtonRef.current.setAttribute("disabled", "disabled");
    setBlockSubmitButton(true);

    const imageUrl = selectedFile ? await imageUpload(selectedFile, profile.za) : "";
    const content = {
      message: message,
      image: imageUrl,
    };

    if (imageUrl) {
      challengeid = "-NTyoROwd2Awv7JO3zKH";
    }

    const response = await createPost(profile, content);

    setCreatedPost(response);

    messageReset();
    setValidImage(false);
    setSelectedFile(null);
    setImagePreview("");
    setOpen(false);
    setBlockSubmitButton(false);

    //Send Challenge
    if (response) {
      await completeChallenge(challengeid, profile.uid, profile.email);
    }
  };

  const handleFileInput = async (event) => {
    const file = event.target.files[0];
    setValidImage(false);

    if (file && file.size > 10485760) {
      setValidImage(true);
      return;
    }
    setSelectedFile(file);
    setImagePreview(URL.createObjectURL(file));

    event.target.value = null;
  };

  const imageUpload = async (fileImage) => {
    const data = new FormData();
    data.append("id_user_firebase", profile.uid);
    data.append("image", fileImage);

    const upload = await uploadImagePosts(data, profile.za);

    return upload;
  };

  const clean = async () => {
    messageReset();
    setValidImage(false);
    setSelectedFile(null);
    setImagePreview("");
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" static className="fixed z-10 inset-0 overflow-y-auto" initialFocus={cancelButtonRef} open={open} onClose={setOpen}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <form className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all w-full max-w-2xl sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full sm:p-6" onSubmit={(event) => formSubmissionHandler(event)}>
              <div>
                <div className="mt-1">
                  <textarea
                    id="message"
                    name="message"
                    rows={12}
                    className={classNames(messageHasError ? "border-red-300" : "border-gray-300", "appearance-none block w-full px-3 py-2 border rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-normal")}
                    value={message}
                    onChange={messageChangeHandler}
                    onBlur={messageBlurHandler}
                  />
                </div>
                {messageHasError && (
                  <p className="mt-2 text-sm text-red-600" id="label-message">
                    Agrega una mensaje que aparecera en el muro
                  </p>
                )}
                <div className="mt-4 w-full rounded-md shadow-sm">
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <div className="flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                      <div className="space-y-1 text-center">
                        {imagePreview ? (
                          <div>
                            <div className="mx-auto h-2 w-auto text-right">
                              <button className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500" tabIndex="0" onClick={clean}>
                                <span className="sr-only">Close panel</span>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="h-6 w-6" aria-hidden="true">
                                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
                                </svg>
                              </button>
                            </div>
                            <img className="mx-auto h-24 w-auto space-y-1 text-center" src={imagePreview} alt="" />
                            <br />
                          </div>
                        ) : (
                          <svg className="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
                            <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                          </svg>
                        )}
                        <div className="flex text-sm text-center text-gray-600">
                          <label htmlFor="file-upload" className="relative cursor-pointer bg-white rounded-md font-medium text-blue-600 hover:text-blue-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-blue-500">
                            <span>Sube una foto o imagen</span>
                            <input id="file-upload" name="file-upload" type="file" className="sr-only" accept=".jpeg, .jpg, .png" onChange={handleFileInput} />
                          </label>
                        </div>
                        <p className="text-xs text-gray-500">PNG, JPG, JPEG de hasta 10MB</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {validImage ? (
                <p className="mt-2 text-sm text-red-600" id="label-message">
                  El archivo es muy grande, intenta con otra o publica sin imagen...
                </p>
              ) : (
                ""
              )}
              <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                <button
                  type="submit"
                  ref={submitButtonRef}
                  className={classNames(
                    blockSubmitButton
                      ? "opacity-50 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-500 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-300 sm:col-start-2 sm:text-sm"
                      : "w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-500 text-base font-medium text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-300 sm:col-start-2 sm:text-sm",
                  )}
                >
                  <div className="mr-4">{blockSubmitButton ? "Publicando" : "Publicar "}</div>
                  {blockSubmitButton ? (
                    <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                  ) : null}
                </button>
                <div
                  className="cursor-pointer mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-300 sm:mt-0 sm:col-start-1 sm:text-sm"
                  onClick={() => {
                    setOpen(false);
                    clean();
                  }}
                  ref={cancelButtonRef}
                >
                  Cancelar
                </div>
              </div>
            </form>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default CreatePost;
