//Libraries
import React, { useState, useEffect } from "react";

//Services
import { getSpeakers } from "../services/firebaseService";

const Speakers = () => {
  const [speakers, setSpeakers] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const data = await getSpeakers();

      setSpeakers(data);
    }
    fetchData();
  }, []);

  return (
    <main className="flex-1 overflow-y-auto">
      <div className="pt-8 mx-auto px-4 sm:px-6 lg:px-8">
        <h1 className="my-4 flex-1 text-2xl font-bold text-gray-600">Conferencistas</h1>

        {/* Questions */}
        <div className="bg-white pb-20">
          {/* Speakers List */}
          <div className="mx-auto py-8 px-4 text-center sm:px-6 lg:px-8 lg:py-16">
            <ul className="mx-auto grid grid-cols-2 lg:gap-16 lg:grid-cols-4">
              {speakers.map((speaker) => (
                <li key={speaker.id} className="mt-4">
                  <div className="space-y-2 lg:space-y-6">
                    <img className="object-cover mx-auto h-32 w-32 rounded-full lg:w-40 lg:h-40" src={speaker.image_url} alt="" />
                    <div className="space-y-1 lg:space-y-2">
                      <div className="text-lg leading-6 font-medium space-y-1">
                        <h3>{speaker.name}</h3>
                        <p className="text-red-600">{speaker.role}</p>
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Speakers;
