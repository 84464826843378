//Libraries
import React, { Fragment, useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import { HomeIcon, VideoCameraIcon, ClipboardListIcon, ChatAltIcon, SupportIcon, UserGroupIcon, XIcon, OfficeBuildingIcon, DocumentDownloadIcon, MicrophoneIcon, StarIcon } from "@heroicons/react/solid";
import QRCode from "react-qr-code";

//Styles
import logo from "../../assets/img/raat/logo_short_white.png";

//Services
import { classNames } from "../../services/tailwindHelpers";
import { RemoveTextAccents } from "../../utils/tools";

const SideMenu = ({ profile, mobileMenuOpen = false, setMobileMenuOpen }) => {
  const location = useLocation();

  const [qrValue, setQrValue] = useState({
    email: "",
    name: "",
  });

  const [navigation, setNavigation] = useState([
    { name: "Muro", href: "home", icon: HomeIcon, current: true, active: true },
    // { name: "En Vivo", href: "live", icon: VideoCameraIcon, current: false, active: true },
    { name: "Agenda", href: "agenda", icon: ClipboardListIcon, current: false, active: true },
    { name: "Conferencistas", href: "speakers", icon: MicrophoneIcon, current: false, active: true },
    // { name: "EXPO", href: "missions", icon: StarIcon, current: false, active: true },
    // { name: "Patrocinadores", href: "sponsors", icon: UserGroupIcon, current: false, active: false },
    // { name: "Stands", href: "stands", icon: OfficeBuildingIcon, current: false, active: false },
    // { name: "Preguntas Panel Gerencial", href: "questions", icon: ChatAltIcon, current: false, active: true },
    { name: "Documentos", href: "documents", icon: DocumentDownloadIcon, current: false, active: true },
    // { name: "Ayuda", href: "help", icon: SupportIcon, current: false, active: true },
  ]);

  useEffect(() => {
    if (profile && profile.email && profile.name && profile.last) {
      setQrValue({
        email: profile.email,
        name: `${RemoveTextAccents(profile.name)} ${RemoveTextAccents(profile.last)}`,
      });
    }
  }, [profile]);

  useEffect(() => {
    handleChangePage(location.pathname.replace("/", ""));
  }, [location]);

  const handleChangePage = (selected_page) => {
    let currentNavigation = [...navigation];

    currentNavigation.map((item) => {
      item.current = item.href === selected_page ? true : false;

      return item;
    });

    setNavigation(currentNavigation);
  };

  return (
    <Fragment>
      {/* Narrow sidebar */}
      <div className="hidden w-28 bg-red-700 overflow-y-auto md:block">
        <div className="w-full py-6 flex flex-col items-center">
          <div className="flex-shrink-0 flex items-center">
            <img className="px-2 h-auto" src={logo} alt="Workflow" />
          </div>
          <div className="flex-1 mt-6 w-full px-1 space-y-1">
            {navigation.map((item) => (
              <NavLink key={item.href} to={item.active ? item.href : ""} onClick={() => handleChangePage(item.href)}>
                {item.active ? (
                  <div className={classNames(item.current ? "bg-red-600 text-white" : "text-red-100 hover:bg-red-600 hover:text-white", "group w-full px-2 py-1.5 rounded-md flex flex-col items-center text-xs font-medium text-center")} aria-current={item.current ? "page" : undefined}>
                    <item.icon className={classNames(item.current ? "text-red-200" : "text-red-300 group-hover:text-red-400", "h-5 w-5")} aria-hidden="true" />
                    <span className="mt-0.5">{item.name}</span>
                  </div>
                ) : (
                  <div className="cursor-default text-red-600 group w-full p-3 rounded-md flex flex-col items-center text-xs font-medium text-center" aria-current={undefined}>
                    <item.icon className="text-red-600 h-5 w-5" aria-hidden="true" />
                    <span className="mt-0.5">{item.name}</span>
                  </div>
                )}
              </NavLink>
            ))}
          </div>
        </div>
      </div>

      {/* Mobile menu */}
      <Transition.Root show={mobileMenuOpen} as={Fragment}>
        <Dialog as="div" static className="fixed inset-0 z-40 flex md:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
          <Transition.Child as={Fragment} enter="transition-opacity ease-linear duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="transition-opacity ease-linear duration-300" leaveFrom="opacity-100" leaveTo="opacity-0">
            <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>
          <Transition.Child as={Fragment} enter="transition ease-in-out duration-300 transform" enterFrom="-translate-x-full" enterTo="translate-x-0" leave="transition ease-in-out duration-300 transform" leaveFrom="translate-x-0" leaveTo="-translate-x-full">
            <div className="relative max-w-xs w-full bg-red-700 pt-5 pb-4 flex-1 flex flex-col">
              <Transition.Child as={Fragment} enter="ease-in-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in-out duration-300" leaveFrom="opacity-100" leaveTo="opacity-0">
                <div className="absolute top-1 right-0 -mr-14 p-1">
                  <button type="button" className="h-12 w-12 rounded-full flex items-center justify-center focus:outline-none focus:ring-2 focus:ring-white" onClick={() => setMobileMenuOpen(false)}>
                    <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                    <span className="sr-only">Close sidebar</span>
                  </button>
                </div>
              </Transition.Child>
              <div className="flex-shrink-0 px-4 flex items-center">
                <img className="h-8 w-auto" src={logo} alt="RATI Logo" />
              </div>
              <div className="mt-5 flex-1 h-0 px-2 overflow-y-auto">
                <nav className="h-full flex flex-col">
                  <div className="space-y-1">
                    {navigation.map((item) => (
                      <NavLink key={item.href} to={item.active ? item.href : ""}>
                        {item.active ? (
                          <div
                            onClick={() => setMobileMenuOpen(false)}
                            className={classNames(item.current ? "bg-red-600 text-white" : "text-red-100 hover:bg-red-600 hover:text-white", "group py-2 px-3 rounded-md flex items-center text-sm font-medium")}
                            aria-current={item.current ? "page" : undefined}
                          >
                            <item.icon className={classNames(item.current ? "text-red-200" : "text-red-300 group-hover:text-red-400", "mr-3 h-6 w-6")} aria-hidden="true" />
                            <span>{item.name}</span>
                          </div>
                        ) : (
                          <div className="cursor-default text-red-600 group py-2 px-3 rounded-md flex items-center text-sm font-medium" aria-current={undefined}>
                            <item.icon className="text-red-600 mr-3 h-6 w-6" aria-hidden="true" />
                            <span>{item.name}</span>
                          </div>
                        )}
                      </NavLink>
                    ))}
                  </div>
                </nav>
              </div>

              {/* QR Code */}


              {/* <div className="pt-4 grid justify-items-center">
                <QRCode value={JSON.stringify(qrValue)} bgColor="#312e81" level={"L"} fgColor="#FFF" size={220} />

                <span className="px-8 mt-4 text-red-300 font-semibold text-xs text-center">Este QR servira para ingresar al evento</span>
              </div> */}
            </div>
          </Transition.Child>
          <div className="flex-shrink-0 w-14" aria-hidden="true">
            {/* Dummy element to force sidebar to shrink to fit close icon */}
          </div>
        </Dialog>
      </Transition.Root>
    </Fragment>
  );
};

export default SideMenu;
