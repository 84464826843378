const config = () => {
  return {
    TENANT: "raat",
    API_URL: "https://container-rati.9vqun1p4opiau.us-east-1.cs.amazonlightsail.com",
    FIREBASE_CONFIG: {
      apiKey: "AIzaSyCp1TNdbTW8RIEB5e45ikIU8aSArvDpojI",
      authDomain: "ratimx.firebaseapp.com",
      databaseURL: "https://ratimx-default-rtdb.firebaseio.com",
      projectId: "ratimx",
      storageBucket: "ratimx.appspot.com",
      messagingSenderId: "675815943922",
      appId: "1:675815943922:web:ae8d46128b7345c7c06a6b",
      measurementId: "G-FTVW2FZ5QV",
    },
    FIRESTORE_CONFIG: {
      getCollection: {
        snapshotListenOptions: { includeMetadataChanges: true },
      },
    },
    ASSETS: {
      rati: {
        logo_full: "../../assets/img/rati/logo.PNG",
        logo_white: "s",
        logo_color: "s",
      },
      radei: {
        logo_full: "./assets/img/radei/logo.PNG",
        logo_white: "s",
        logo_color: "s",
      },
      raln: {
        logo_full: "./assets/img/raln/logo.PNG",
        logo_white: "s",
        logo_color: "s",
      },
      oxxoconn: {
        logo_full: "../../assets/img/oxxoconn/logo.PNG",
        logo_white: "s",
        logo_color: "s",
      },
      raat: {
        logo_full: "../../assets/img/raat/logo.PNG",
        logo_white: "s",
        logo_color: "s",
      },
    },
    EVENT: {
      rati: {
        start_date: "20230523",
      },
      radei: {
        start_date: "20230808",
      },
      raat: {
        start_date: "20240524",
      },
    },
    FEATURES: {
      streaming: false,
    },
  };
};

module.exports = config();
