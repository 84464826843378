//Libraries
import axios from "axios";

//Config
import { API_URL } from "../config.json";

//Information
export const sendPictureProfile = async (data, user_token = "") => {
  const config = {
    headers: { Authorization: `Bearer ${user_token}` },
  };

  let res = await axios.put(`${API_URL}/rati/users/image`, data, config).catch((e) => {
    console.log("[profileService.js][sendPictureProfile] Error: ", e);

    return false;
  });

  if (res && res.status !== 200) return null;

  return res.data ? res.data.url : "";
};

// Health
export const sendVaccine = async (data) => {
  let res = await axios.post(`${API_URL}/rati/vaccination`, data).catch((e) => {
    console.log("[profileService.js][sendVaccine] Error: ", e);

    return false;
  });

  if (res && res.status !== 200) return null;

  return res.data.data;
};

export const getVaccines = async (userId) => {
  let res = await axios.get(`${API_URL}/rati/vaccination/${userId}`).catch((e) => {
    console.log("[profileService.js][getVaccines] Error: ", e);

    return false;
  });

  if (res && res.status !== 200) return false;

  return res.data ? res.data.data : [];
};

export const deleteVaccine = async (userId, vaccine_id) => {
  let body = {
    vaccinationId: vaccine_id,
  };

  let res = await axios.delete(`${API_URL}/rati/vaccination`, { data: body }).catch((e) => {
    console.log("[profileService.js][DeleteVaccine] Error: ", e);

    return false;
  });

  if (res && res.status !== 200) return false;

  return true;
};
