//Libraries
import React, { Fragment, useEffect, useState } from "react";
import { Dialog, Transition, Listbox } from "@headlessui/react";
import { XIcon, ChatAltIcon, CheckIcon, SelectorIcon } from "@heroicons/react/solid";

//Components
import Iframe from "./Iframe";
import { RatiChat } from "./shared/Chat";
import { RatiReactions } from "./shared/Reactions";

//Services
import { classNames } from "../services/tailwindHelpers";
import channels from "../assets/json/Live/channels.json";
import { createSession, updateSession } from "../services/firebaseService";

//Temp const
const MINUTE_MS = 15000; //300000;

const Live = ({ profile }) => {
  const [displayZoom, setDisplayZoom] = useState(false);
  const [zoomHeight, setZoomHeight] = useState(500);
  const [openPanelChat, setOpenPanelChat] = useState(false);

  const [selectedChannel, setSelectedChannel] = useState(channels[0]);
  const [sessionID, setSessionID] = useState(null);

  useEffect(async () => {
    let currentSessionID = await createSession(profile, "/live");

    setSessionID(currentSessionID);

    const interval = setInterval(() => {
      if (currentSessionID !== null || sessionID !== null) {
        updateSession(sessionID ? sessionID : currentSessionID);
      }
    }, MINUTE_MS);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const new_height = document.getElementById("zoom-div").clientHeight;
    setZoomHeight(new_height && typeof new_height === "number" ? Math.trunc(new_height * 0.7) : 500);

    const timeout = setTimeout(() => {
      setDisplayZoom(true);
    }, 1500);

    return () => clearTimeout(timeout);
  }, [displayZoom]);

  const handleSelectChannel = (event) => {
    setSelectedChannel(event);
  };

  return (
    <div className="flex-1 flex items-stretch overflow-hidden">
      <main id="zoom-div" className="flex-1 h-100 overflow-y-auto">
        <div className="pt-8 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="lg:flex md:items-center md:justify-between">
            <h1 className="my-4 text-2xl font-bold text-gray-600 max-w-full">RATI Live</h1>

            {/* Buttons */}
            <div className="mt-4 flex items-center justify-between md:mt-0">
              <div className="inline-flex">
                <Listbox value={selectedChannel} onChange={handleSelectChannel}>
                  {({ open }) => (
                    <>
                      <div className="mt-3 lg:px-4 lg:mr-1 relative md:mx-0 md:px-0">
                        <Listbox.Button className="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-3 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 sm:text-sm">
                          <span className="block truncate">{selectedChannel.name}</span>
                          <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                            <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                          </span>
                        </Listbox.Button>

                        <Transition show={open} as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
                          <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                            {channels.map((channel) => (
                              <Listbox.Option key={channel.id} className={({ active }) => classNames(active ? "text-white bg-blue-600" : "text-gray-900", "cursor-default select-none relative py-2 pl-3 pr-9")} value={channel}>
                                {({ selectedChannel, active }) => (
                                  <>
                                    <span className={classNames(selectedChannel ? "font-semibold" : "font-normal", "block truncate")}>{channel.name}</span>

                                    {selectedChannel ? (
                                      <span className={classNames(active ? "text-white" : "text-blue-600", "absolute inset-y-0 right-0 flex items-center pr-4")}>
                                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                      </span>
                                    ) : null}
                                  </>
                                )}
                              </Listbox.Option>
                            ))}
                          </Listbox.Options>
                        </Transition>
                      </div>
                    </>
                  )}
                </Listbox>
              </div>
              <button className="ml-1 hidden lg:inline-flex items-center my-3 px-2.5 py-3 border border-transparent text-sm font-medium rounded text-white bg-blue-500 hover:bg-blue-600 focus:outline-none" onClick={() => setOpenPanelChat(true)}>
                Abrir chat
              </button>

              {/* Responsive buttons */}
              <button className="flex lg:hidden my-2 py-3 px-3 border border-transparent rounded-full shadow-sm text-base font-medium text-white bg-blue-600 lg:w-48  focus:outline-none hover:bg-blue-700" onClick={() => setOpenPanelChat(true)}>
                <ChatAltIcon className="m-auto h-5 w-5 text-white" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          {/* Zoom Iframe */}
          <section className={classNames(displayZoom ? "mt-2 py-4" : "mt-8 py-8 bg-gray-200", "")}>
            {!displayZoom ? (
              <div className="rounded-md max-w-2xl w-full mx-auto">
                <div className="animate-pulse space-y-4">
                  <div className="mx-auto rounded-sm bg-gray-500 h-80 w-3/4"></div>
                  <div className="mt-2 flex space-x-4">
                    <div className="mx-auto bg-gray-500 rounded h-16 w-3/4"></div>
                  </div>
                  <div className="mt-2 flex space-x-4">
                    <div className="mx-auto bg-gray-500 rounded h-16 w-1/3"></div>
                  </div>
                </div>
              </div>
            ) : null}
            <div className={classNames(displayZoom ? "visible" : "invisible h-1", "")}>
              <Iframe profile={profile} height={zoomHeight} type={selectedChannel.type} url={selectedChannel.url} allow="autoplay" />
            </div>
          </section>
        </div>
      </main>

      {/* Side Chat */}
      <Transition.Root show={openPanelChat} as={Fragment}>
        <Dialog as="div" static className="fixed inset-0 overflow-hidden" open={openPanelChat} onClose={setOpenPanelChat}>
          <div className="absolute inset-0 overflow-hidden">
            <Dialog.Overlay className="absolute inset-0" />

            <div className="fixed mt-16 inset-y-0 right-0 pl-10 max-w-full flex">
              <Transition.Child as={Fragment} enter="transform transition ease-in-out duration-500 sm:duration-700" enterFrom="translate-x-full" enterTo="translate-x-0" leave="transform transition ease-in-out duration-500 sm:duration-700" leaveFrom="translate-x-0" leaveTo="translate-x-full">
                <div className="w-screen max-w-md">
                  <div className="h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl">
                    <div className="min-h-0 flex-1 flex flex-col py-6">
                      <div className="px-4 sm:px-6 ">
                        <div className="flex items-start pb-12 justify-between">
                          <Dialog.Title className="text-lg font-medium text-gray-900">Chat en vivo</Dialog.Title>
                          <div className="ml-3 h-7 flex items-center">
                            <button className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500" onClick={() => setOpenPanelChat(false)}>
                              <span className="sr-only">Close panel</span>
                              <XIcon className="h-6 w-6" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                        {/* Reactions */}
                        <div className="">{openPanelChat && <RatiReactions profile={profile} />}</div>
                      </div>

                      {openPanelChat && <RatiChat profile={profile} />}
                    </div>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
};

export default Live;
