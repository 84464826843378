//Libraries
import axios from "axios";

//Config
import { API_URL } from "../config.json";

export const getSchedule = async (date, userId, user_token = "") => {
  const config = {
    headers: { Authorization: `Bearer ${user_token}` },
  };

  const res = await axios.get(`${API_URL}/rati/schedule/v2/${date}/${userId}`, config).catch((e) => {
    console.log("Error [agendaService.js][getSchedule] : ", e);

    return [];
  });

  if (res && res.status !== 200) return [];

  return res.data && res.data.data;
};
