import { Fragment, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { SpeakerphoneIcon } from "@heroicons/react/outline";

export default function ForgotPassword({ open, setOpen }) {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" static className="fixed z-10 inset-0 overflow-y-auto" open={open} onClose={setOpen}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-md sm:w-full sm:p-6">
              <div>
                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100">
                  <SpeakerphoneIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                </div>
                <div className="mt-3 text-center sm:mt-5">
                  <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                    ¿Olvidaste tu contraseña?
                  </Dialog.Title>
                  <div className="mt-4">
                    <p className="text-sm text-gray-500">
                      La confirmacion y contraseña fueron enviadas previamente a tu correo. Tu{" "}
                      <span href="#" className="text-red-500 font-bold">
                        numero de empleado
                      </span>{" "}
                      es tu contraseña.
                    </p>
                    <br></br>
                    <p className="text-sm text-gray-500">
                      Si aun cuentas con problemas puedes unirte al liga de{" "}
                      <a
                        href="https://teams.microsoft.com/l/meetup-join/19%3ameeting_ZjQ3ZTU3ZmYtZjRlNC00YThkLWIwNDItNDZmYzc4NmEzZmIz%40thread.v2/0?context=%7b%22Tid%22%3a%22caca9011-7b6a-44de-861f-095a2ca883b7%22%2c%22Oid%22%3a%226f838e32-bb76-4c05-8256-5d058dac3634%22%7d"
                        className="text-red-500 font-bold"
                      >
                        Teams para mas ayuda
                      </a>
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-6">
                <button
                  type="button"
                  className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:text-sm"
                  onClick={() => setOpen(false)}
                >
                  Aceptar
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
