import React from "react";

//Styles
import mapLandscape from "../assets/img/stands/layout_rati_2022_landscape.jpg";
import mapPortrait from "../assets/img/stands/layout_rati_2022_portrait.jpg";

const Stands = ({}) => {
  return (
    <main className="flex-1 overflow-y-auto">
      <div className="p-6 lg:p-12">
        <img className="h-auto hidden lg:block" src={mapLandscape} alt="Map Stands" />
        <img className="h-auto lg:hidden" src={mapPortrait} alt="Map Stands" />
      </div>
    </main>
  );
};

export default Stands;
