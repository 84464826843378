//Libraries
import firebase from "firebase";

export const login = async (email, password) => {
  return await firebase
    .auth()
    .signInWithEmailAndPassword(email, password)
    .catch((err) => {
      switch (err.code) {
        case "auth/invalid-email":
        case "auth/user-disabled":
        case "auth/user-not-found":
        case "auth/wrong-password":
          console.error(`[firebaseService.js][login][user: ${email}] Error : ${err.message}`);

          return { status: false, message: "Usuario y/o contraseña son invalidas" };
        default:
          return { status: true, message: "" };
      }
    });
};

export const logout = () => {
  firebase.auth().signOut();
};

export const sesion = async () => {
  return await firebase.auth().onAuthStateChanged((user) => {
    if (user) return user;

    return "";
  });
};

export const getToken = async () => {
  return await firebase
    .auth()
    .currentUser.getIdToken(true)
    .then((token) => {
      if (token) return token;

      return "";
    });
};

export const getUserById = async (id) => {
  const db = firebase.firestore();

  const doc = await db.collection("users").doc(id).get();

  return { uid: doc.id, ...doc.data() };
};

export const getReactions = async () => {
  const db = firebase.firestore();

  const reactions = await db.collection("reactions").doc("--reaction--").get();

  return reactions.data();
};

export const reactionsPost = async (value) => {
  const db = firebase.firestore();
  const increment = firebase.firestore.FieldValue.increment(1);

  const recations = {
    thumbsup: { thumbsup: increment },
    clap: { clap: increment },
    sunglasess: { sunglasess: increment },
    raised_hands: { raised_hands: increment },
    open_mount: { open_mount: increment },
  };
  const reactionObj = recations[value] || null;

  if (reactionObj) {
    const snapshot = await db
      .collection("reactions")
      .doc("--reaction--")
      .update(reactionObj)
      .then(() => true)
      .catch((err) => {
        console.log("[firebaseService.js][reactionsPost] Error: ", err);

        return false;
      });

    return snapshot;
  }
  return null;
};

export const updatePost = async (post_id, likes) => {
  const db = firebase.firestore();

  const snapshot = await db
    .collection("posts")
    .doc(post_id)
    .update({ likes: likes })
    .then(() => true)
    .catch((err) => {
      console.log("[firebaseService.js][updatePost] Error: ", err);

      return false;
    });

  return snapshot;
};

export const createPost = async (profile, content) => {
  const db = firebase.firestore();

  const postRef = await db
    .collection("posts")
    .add({
      user: {
        uid: profile.uid,
        fullname: `${profile.name} ${profile.last}`,
        url_photo: profile.url_photo,
        user_reference: db.doc("users/" + profile.uid),
      },
      content: content.message,
      likes: [],
      comments: [],
      date: firebase.firestore.FieldValue.serverTimestamp(),
      images: [content.image],
    })
    .then((docAdded) => {
      return docAdded.id;
    })
    .catch((err) => {
      console.log("[firebaseService.js][createPost] Error: ", err);

      return false;
    });

  if (postRef) {
    return {
      id: postRef,
      user: {
        uid: profile.uid,
        fullname: `${profile.name} ${profile.last}`,
        url_photo: profile.url_photo,
        user_reference: db.doc("users/" + profile.uid),
      },
      content: content.message,
      likes: [],
      comments: [],
      date: null,
      images: [content.image],
    };
  }

  return postRef;
};

export const createPostComment = async (post_id, profile, content) => {
  const db = firebase.firestore();

  const snapshot = await db
    .collection("posts")
    .doc(post_id)
    .update({
      comments: firebase.firestore.FieldValue.arrayUnion({
        user: {
          uid: profile.uid,
          fullname: `${profile.name} ${profile.last}`,
          url_photo: profile.url_photo,
          user_reference: db.doc("users/" + profile.uid),
        },
        date: firebase.firestore.Timestamp.now(),
        content: content,
      }),
    })
    .then(() => true)
    .catch((err) => {
      console.log("[firebaseService.js][createPostComment] Error: ", err);

      return false;
    });

  return snapshot;
};

export const deletePost = async (post_id) => {
  const db = firebase.firestore();

  const snapshot = await db
    .collection("posts")
    .doc(post_id)
    .delete()
    .then(() => true)
    .catch((err) => {
      console.log("[firebaseService.js][deletePost] Error: ", err);

      return false;
    });

  return snapshot;
};

export const reportPost = async (post_id) => {
  const db = firebase.firestore();
  const increment = firebase.firestore.FieldValue.increment(1);

  const snapshot = await db
    .collection("posts")
    .doc(post_id)
    .update({ reports: increment })
    .then(() => true)
    .catch((err) => {
      console.log("[firebaseService.js][reportPost] Error: ", err);

      return false;
    });

  return snapshot;
};

// Questions

export const createQuestion = async (user, topic, question) => {
  const db = firebase.firestore();

  const postRef = await db
    .collection("questions")
    .add({
      user: db.doc("users/" + user.uid),
      username: user.displayName,
      topic: topic,
      question: question,
      date: firebase.firestore.FieldValue.serverTimestamp(),
    })
    .then((docAdded) => {
      return docAdded.id;
    })
    .catch((err) => {
      console.log("[firebaseService.js][createQuestion] Error: ", err);

      return false;
    });

  return postRef ? true : false;
};

// Speakers

export const getSpeakers = async () => {
  const db = firebase.firestore();

  const snapshot = await db.collection("speakers").orderBy("name").get();

  let data = [];

  snapshot.forEach((doc) => {
    data.push({ id: doc.id, ...doc.data() });
  });

  return data ? data : [];
};

// Sponsors

export const getSponsors = async () => {
  const db = firebase.firestore();

  const snapshot = await db.collection("sponsors").orderBy("title").get();

  let data = [];

  snapshot.forEach((doc) => {
    data.push({ id: doc.id, ...doc.data() });
  });

  return data ? data : [];
};

// Review

export const createReview = async (schedule_id, schedule_name, stars, message, user) => {
  const db = firebase.firestore();

  const postRef = await db
    .collection("reviews")
    .add({
      user: db.doc("users/" + user.uid),
      username: `${user.name} ${user.last}`,
      id_schedule: schedule_id,
      schedule_name: schedule_name,
      stars: stars,
      message: message,
      date: firebase.firestore.FieldValue.serverTimestamp(),
    })
    .then((docAdded) => {
      return docAdded.id;
    })
    .catch((err) => {
      console.log("[firebaseService.js][createReview] Error: ", err);

      return false;
    });

  return postRef ? true : false;
};

// Viewers

export const createSession = async (user, path) => {
  const db = firebase.firestore();

  const postRef = await db
    .collection("viewers")
    .add({
      uid: user.uid,
      email: user.email,
      user: db.doc("users/" + user.uid),
      path: path,
      sessionStart: firebase.firestore.FieldValue.serverTimestamp(),
      sessionEnd: firebase.firestore.FieldValue.serverTimestamp(),
    })
    .then((docAdded) => {
      return docAdded.id;
    })
    .catch((err) => {
      console.log("[firebaseService.js][createSession] Error: ", err);

      return false;
    });

  return postRef ? postRef : null;
};

export const updateSession = async (session_id) => {
  const db = firebase.firestore();

  const snapshot = await db
    .collection("viewers")
    .doc(session_id)
    .update({ sessionEnd: firebase.firestore.FieldValue.serverTimestamp() })
    .then(() => true)
    .catch((err) => {
      console.log("[firebaseService.js][updateSession] Error: ", err);

      return false;
    });

  return snapshot ? true : false;
};
