//Libraries
import React from "react";

//Components
import { RatiChat } from "../shared/Chat";
import { RatiReactions } from "../shared/Reactions";

const Chat = ({ profile }) => {
  return (
    <div className="flex-1 flex items-stretch overflow-hidden">
      <main id="zoom-div" className="overflow-y-auto">
        <div className="pt-8 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex md:items-center md:justify-between">
            <h1 className="my-4 flex-1 text-2xl font-bold text-gray-600">RATI Live - Chat</h1>
          </div>
        </div>

        {/* Reactions */}
        <div className="absolute left-1/3 bottom-12">
          <RatiReactions profile={profile} />
        </div>

        <div className="px-8 pb-20">
          <RatiChat profile={profile} />
        </div>
      </main>
    </div>
  );
};

export default Chat;
