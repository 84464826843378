//Libraries
import React, { useState, useEffect } from "react";
import { UserIcon, HeartIcon } from "@heroicons/react/solid";

//Components
import Health from "./Profile/Health";
import Information from "./Profile/Information";

//Services
import { classNames } from "../services/tailwindHelpers";

const profile_tabs = [
  { id: 1, name: "Información", href: "#", icon: UserIcon, current: true },
  //{ id: 2, name: "Salud", href: "#", icon: HeartIcon, current: false },
  //{ id: 3, name: "Ajustes", href: "#", icon: CogIcon, current: false },
];

const Profile = ({ user, profile, setProfile }) => {
  const [tabs, setTabs] = useState([...profile_tabs]);
  const [currentTab, setCurrentTab] = useState(1);

  const handleChangeTab = async (tab_id) => {
    let current_tabs = [...tabs];
    tab_id = tab_id && tab_id.target && tab_id.target.value ? Number(tab_id.target.value) : tab_id;
    tab_id = Number(tab_id);

    current_tabs = current_tabs.map((tab) => {
      tab.current = tab.id === tab_id ? true : false;

      return tab;
    });

    setCurrentTab(tab_id);
    setTabs(current_tabs);
  };

  useEffect(() => {
    let currents = tabs.map((tab) => {
      tab.current = tab.id === currentTab ? true : false;

      return tab;
    });

    setTabs(currents);
  }, []);

  return (
    <main className="flex-1 overflow-y-auto">
      <div className="pt-8 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h1 className="mb-8 flex-1 text-2xl font-bold text-gray-700">Perfil</h1>

        <div className="bg-white rounded-lg px-4 pt-5 pb-4 ">
          {/* Tabs */}
          <div className="sm:hidden">
            <label htmlFor="tabs" className="sr-only">
              Select a tab
            </label>
            {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
            {/* <select id="tabs" name="tabs" className="block w-full focus:ring-blue-500 focus:border-blue-500 border-gray-300 rounded-md" defaultValue={currentTab} onChange={handleChangeTab}>
              {tabs.map((tab) => (
                <option key={tab.id} value={tab.id}>
                  {tab.name}
                </option>
              ))}
            </select> */}
          </div>
          <div className="hidden sm:block">
            <div className="border-b border-gray-200">
              <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                {tabs.map((tab) => (
                  <a
                    key={tab.name}
                    className={classNames(tab.current ? "border-blue-500 text-blue-600" : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300", "group inline-flex items-center py-4 px-1 border-b-2 font-medium text-sm cursor-pointer")}
                    aria-current={tab.current ? "page" : undefined}
                    onClick={() => handleChangeTab(tab.id)}
                  >
                    <tab.icon className={classNames(tab.current ? "text-blue-500" : "text-gray-400 group-hover:text-gray-500", "-ml-0.5 mr-2 h-5 w-5")} aria-hidden="true" />
                    <span>{tab.name}</span>
                  </a>
                ))}
              </nav>
            </div>
          </div>

          {/* Content */}
          {currentTab === 1 && <Information user={user} profile={profile} setProfile={setProfile} />}
          {currentTab === 2 && <Health user={user} profile={profile} />}
        </div>
      </div>
    </main>
  );
};

export default Profile;
