//Libraries
import React, { useState, useEffect } from "react";
import { AtSymbolIcon, GlobeAltIcon } from "@heroicons/react/outline";

//Services
import { getSponsors } from "../services/firebaseService";

const Sponsors = () => {
  const [sponsorsTitanium, setSponsorsTitanium] = useState([]);
  const [sponsorsDiamante, setSponsorsDiamante] = useState([]);
  const [sponsorsPlatino, setSponsorsPlatino] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const data = await getSponsors();

      setSponsorsTitanium(data.filter((i) => i.tier === "Titanium"));
      setSponsorsDiamante(data.filter((i) => i.tier === "Diamante"));
      setSponsorsPlatino(data.filter((i) => i.tier === "Platino"));
    }
    fetchData();
  }, []);

  return (
    <main className="flex-1 overflow-y-auto">
      <div className="pt-8 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* High Sponsors */}
        <div className="my-4">
          <div className="relative bg-gray-50 pt-16 pb-20 px-4 sm:px-6 lg:pt-12 lg:pb-12 lg:px-8">
            <div className="absolute inset-0">
              <div className="h-1/3 sm:h-2/3" />
            </div>
            <div className="relative max-w-7xl mx-auto">
              <div className="text-center">
                <h2 className="text-3xl tracking-tight font-extrabold text-gray-700 sm:text-4xl">Titanium</h2>
                <p className="mt-3 max-w-2xl mx-auto text-xl text-gray-500 sm:mt-4"></p>
              </div>
              <div className="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
                {sponsorsTitanium.map((sponsor) => (
                  <div key={sponsor.title} className="flex flex-col rounded-lg shadow-lg overflow-hidden">
                    <a href={sponsor.website_url} className="flex-shrink-0 bg-white" target="_blank" rel="noopener noreferrer">
                      <img className="pt-8 bg-white w-full h-auto" src={sponsor.logo_url} alt="" />
                    </a>
                    <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                      <div className="flex-1">
                        <div className="block mt-2">
                          <a href={sponsor.website_url} className="text-xl font-semibold text-gray-900" target="_blank" rel="noopener noreferrer">
                            {sponsor.title}
                          </a>
                          <p className="mt-3 text-base text-gray-500">{sponsor.description}</p>
                        </div>
                      </div>
                      <div className="mt-6 flex items-center">
                        <div className="">
                          <div className="flex text-sm text-gray-500">
                            <AtSymbolIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                            <p>{sponsor.contact_email}</p>
                          </div>
                          <a href={sponsor.website_url} className="flex text-sm text-gray-500" target="_blank" rel="noopener noreferrer">
                            <GlobeAltIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                            <p>{sponsor.website_name}</p>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        {/* Medium Sponsors */}
        <div className="my-4">
          <div className="relative bg-gray-50 pt-16 pb-20 px-4 sm:px-6 lg:pt-12 lg:pb-12 lg:px-8">
            <div className="absolute inset-0">
              <div className="h-1/3 sm:h-2/3" />
            </div>
            <div className="relative max-w-7xl mx-auto">
              <div className="text-center">
                <h2 className="text-3xl tracking-tight font-extrabold text-gray-600 sm:text-4xl">Diamante</h2>
                <p className="mt-3 max-w-2xl mx-auto text-xl text-gray-500 sm:mt-4"></p>
              </div>
              <div className="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
                {sponsorsDiamante.map((sponsor) => (
                  <div key={sponsor.title} className="flex flex-col rounded-lg shadow-lg overflow-hidden">
                    <a href={sponsor.website_url} className="flex-shrink-0 bg-white" target="_blank" rel="noopener noreferrer">
                      <img className="pt-8 mx-auto bg-white w-auto h-24" src={sponsor.logo_url} alt="" />
                    </a>
                    <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                      <div className="flex-1">
                        <div className="block mt-2">
                          <a href={sponsor.website_url} className="text-xl font-semibold text-gray-900" target="_blank" rel="noopener noreferrer">
                            {sponsor.title}
                          </a>
                          <p className="mt-3 text-base text-gray-500">{sponsor.description}</p>
                        </div>
                      </div>
                      <div className="mt-6 flex items-center">
                        <div className="">
                          <div className="flex text-sm text-gray-500">
                            <AtSymbolIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                            <p>{sponsor.contact_email}</p>
                          </div>
                          <a href={sponsor.website_url} className="flex text-sm text-gray-500" target="_blank" rel="noopener noreferrer">
                            <GlobeAltIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                            <p>{sponsor.website_name}</p>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        {/* Basic Sponsors */}
        <div className="my-4">
          <div className="relative bg-gray-50 pt-16 pb-20 px-4 sm:px-6 lg:pt-12 lg:pb-12 lg:px-8">
            <div className="absolute inset-0">
              <div className="h-1/3 sm:h-2/3" />
            </div>
            <div className="relative max-w-7xl mx-auto">
              <div className="text-center">
                <h2 className="text-3xl tracking-tight font-extrabold text-gray-500 sm:text-4xl">Platino</h2>
                <p className="mt-3 max-w-2xl mx-auto text-xl text-gray-500 sm:mt-4"></p>
              </div>
              <div className="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-4 lg:max-w-none">
                {sponsorsPlatino.map((sponsor) => (
                  <div key={sponsor.title} className="flex flex-col rounded-lg shadow-lg overflow-hidden">
                    <a href={sponsor.website_url} className="flex-shrink-0 bg-white" target="_blank" rel="noopener noreferrer">
                      <img className="pt-8 mx-auto bg-white w-auto h-24" src={sponsor.logo_url} alt="" />
                    </a>
                    <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                      <div className="flex-1 -ml-2">
                        <div className="block mt-2">
                          <a href={sponsor.website_url} className="text-xl font-semibold text-gray-900" target="_blank" rel="noopener noreferrer">
                            {sponsor.title}
                          </a>
                          <p className="mt-3 text-base text-gray-500"></p>
                        </div>
                      </div>
                      <div className="mt-6 flex items-center">
                        <div className="-ml-4">
                          <div className="flex text-xs text-gray-500">
                            <AtSymbolIcon className="flex-shrink-0 mr-1.5 h-4 w-4 text-gray-400" aria-hidden="true" />
                            <p>{sponsor.contact_email}</p>
                          </div>
                          <a href={sponsor.website_url} className="flex text-xs text-gray-500" target="_blank" rel="noopener noreferrer">
                            <GlobeAltIcon className="flex-shrink-0 mr-1.5 h-4 w-4 text-gray-400" aria-hidden="true" />
                            <p>{sponsor.website_name}</p>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Sponsors;
