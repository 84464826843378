// Libraries
import React, { useEffect, useRef, useState, useContext, Fragment } from "react";
import { ChatAltIcon, PaperAirplaneIcon } from "@heroicons/react/solid";
import TimeAgo from "react-timeago";

// Firebase Config
import { FirebaseContext } from "../../utils/firebase";
import "firebase/firestore";
import "firebase/auth";

import { useAuthState } from "react-firebase-hooks/auth";
import { useCollectionData } from "react-firebase-hooks/firestore";

//Services
import { classNames } from "../../services/tailwindHelpers";

function RatiChat({ profile }) {
  const firebase = useContext(FirebaseContext);
  const auth = firebase.auth();
  const [user] = useAuthState(auth);

  return <Fragment>{user ? <ChatRoom profile={profile} /> : <withoutSesion />}</Fragment>;
}

function ChatRoom({ profile }) {
  const firebase = useContext(FirebaseContext);
  const auth = firebase.auth();
  const firestore = firebase.firestore();

  const dummy = useRef();
  const messagesRef = firestore.collection("messages");
  const query = messagesRef.orderBy("createdAt", "asc").limitToLast(25);

  const [messages] = useCollectionData(query, { idField: "id" });
  const [formValue, setFormValue] = useState("");
  const submitButtonRef = useRef(null);
  const [blockSubmitButton, setBlockSubmitButton] = useState(false);

  useEffect(() => {
    dummy.current.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  const sendMessage = async (e) => {
    if (/\S/.test(formValue)) {
      e.preventDefault();
      if (submitButtonRef.current) submitButtonRef.current.setAttribute("disabled", "disabled");
      const text = formValue.trim();
      setBlockSubmitButton(true);
      setFormValue("");

      const { uid } = auth.currentUser;
      const { name, last, url_photo } = profile;

      await messagesRef.add({
        user: `${name} ${last}`,
        body: text,
        createdAt: new Date(),
        uid: uid,
        photoURL: url_photo,
      });

      dummy.current.scrollIntoView({ behavior: "smooth" });
    } else {
      e.preventDefault();
    }
    setFormValue("");
    setBlockSubmitButton(false);
  };

  return (
    <Fragment>
      <div className="mt-12 relative flex-1 px-4 sm:px-6 lg:mt-20 overflow-y-scroll">
        {/* Replace with your content */}
        <div className="h-full" aria-hidden="true">
          <div className="flow-root">
            <ul className="-mb-8">{messages && messages.map((msg, msgIdx) => <ChatMessage key={msg.id} message={msg} msgIdx={msgIdx} msgLength={messages.length} />)}</ul>
            <span ref={dummy}></span>
          </div>
        </div>
      </div>
      <form className="flex px-4 pt-4 flex justify-end" onSubmit={sendMessage}>
        <input
          type="text"
          id="message"
          name="message"
          className="block w-full mr-2 border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-light-blue-500 focus:border-light-blue-500 sm:text-sm"
          placeholder=""
          aria-label="Full name"
          value={formValue}
          onChange={(e) => setFormValue(e.target.value)}
        />
        <button
          type="submit"
          ref={submitButtonRef}
          disabled={!formValue}
          className={classNames(
            blockSubmitButton
              ? "opacity-50 block bg-blue-500 inline-flex items-center px-2 shadow-sm text-sm font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-light-blue-500"
              : "hover:bg-blue-600 bg-blue-500 inline-flex items-center px-2 shadow-sm text-sm font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-light-blue-500",
          )}
        >
          <PaperAirplaneIcon className="mx-1 h-5 w-5 text-white" aria-hidden="true" />
        </button>
      </form>
    </Fragment>
  );
}

function ChatMessage(props) {
  const { user, body, uid, photoURL, createdAt, msgIdx, msgLength } = props.message;

  let date = "";

  if (createdAt && createdAt.seconds) {
    try {
      date = new Date(createdAt.seconds * 1000);
    } catch (e) {}
  }

  return (
    <li key={uid}>
      <div className="relative pb-8">
        {msgIdx !== msgLength - 1 ? <span className="absolute top-5 left-5 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" /> : null}
        <div className="relative flex items-start space-x-3">
          <div className="relative">
            <img className="h-10 w-10 rounded-full bg-gray-400 flex items-center justify-center ring-8 ring-white" src={photoURL} alt="" />
            <span className="absolute -bottom-0.5 -right-1 bg-white rounded-tl px-0.5 py-px">
              <ChatAltIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
            </span>
          </div>
          <div className="min-w-0 flex-1">
            <div>
              <div className="text-sm">
                <a href="" className="font-medium text-gray-900">
                  {user}
                </a>
              </div>
              <p className="mt-0.5 text-sm text-gray-500">Comento {date ? <TimeAgo date={date} /> : ""}</p>
            </div>
            <div className="mt-2 text-sm text-gray-700">
              <p>{body}</p>
            </div>
          </div>
        </div>
      </div>
    </li>
  );
}

export { RatiChat };
