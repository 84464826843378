//Libraries
import { Fragment } from "react";
import { Listbox, Transition } from "@headlessui/react";

//Services
import { classNames } from "../../services/tailwindHelpers";

export default function Likes({ users }) {
  return (
    <Listbox>
      {({ open }) => (
        <>
          <Listbox.Label className="block font-medium text-gray-900"></Listbox.Label>
          <div className="relative">
            <Listbox.Button className="px-2 relative cursor-pointer hover:underline">{users ? users.length : 0}</Listbox.Button>

            <Transition show={open} as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
              <Listbox.Options className="absolute z-10 bg-gray-200 opacity-90 shadow-lg max-h-60 rounded-md py-1 text-sm ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none">
                {users.map((user) => (
                  <Listbox.Option key={user.uid} className={({ active }) => classNames(active ? "text-white bg-blue-600" : "text-gray-900", "cursor-default select-none relative py-1 pl-3 pr-9")} value={user}>
                    <span className="font-normal block truncate">{user.fullname}</span>
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}
