//Libraries
import React, { useState, useContext, useEffect } from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { isIE } from "react-device-detect";
import ReactGA from "react-ga4";
import { useCollection } from "react-firebase-hooks/firestore";

import { FirebaseContext } from "./utils/firebase";

//Config
import { FIRESTORE_CONFIG } from "./config.json";

//Components
import SideMenu from "./components/shared/SideMenu";
import TopMenu from "./components/shared/TopMenu";

//Components
import Home from "./components/Home";
import Live from "./components/Live";
import Agenda from "./components/Agenda";
import Speakers from "./components/Speakers";
import Sponsors from "./components/Sponsors";
import Stands from "./components/Stands";
import Questions from "./components/Questions";
import Help from "./components/Help";
import Documents from "./components/Documents";
import Login from "./components/Login/Login";
import FloatingVideo from "./components/FloatingVideo";
import Profile from "./components/Profile";
import Missions from "./components/Missions";

//Config
import { FEATURES } from "./config.json";

//Components for internal
import Chat from "./components/Internal/Chat";

import CreatePost from "./components/Modals/CreatePost";

//Styles
import "./App.css";

//Assets
import browsie from "./assets/img/browsie.png";
import gaPages from "./assets/json/App/pages.json";

function usePageViews(location) {
  useEffect(() => {
    if (!window.GA_INITIALIZED) {
      ReactGA.initialize("G-4NDJMG95ZK");
      window.GA_INITIALIZED = true;
    }

    ReactGA.send({ hitType: "pageview", page: location.pathname, title: gaPages[location.pathname] || "Untitled" });
  }, [location]);
}

function App() {
  let location = useLocation();
  usePageViews(location);

  const firebase = useContext(FirebaseContext);
  const [user, loading, error] = useAuthState(firebase.auth());

  const [profile, setProfile] = useState({
    uid: "",
    name: "",
    last: "",
    email: "",
    theme: "",
    url_photo: "",
    za: "",
  });

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [openModalCreatePost, setOpenModalCreatePost] = useState(false);
  const [createdPost, setCreatedPost] = useState(null);
  const [openFloatingVideo, setOpenFloatingVideo] = useState(false);

  const [activities] = useCollection(firebase.firestore().collection("activity").orderBy("date", "desc"), FIRESTORE_CONFIG.getCollection);

  useEffect(() => {
    if (user) {
      setProfile(user);
    }
  }, [user]);

  const handleSetProfile = (usr) => {
    setProfile(usr);
  };

  const handleOpenMobileMenu = (state) => {
    setMobileMenuOpen(state);
  };

  const handleStatusModalCreatePost = (state) => {
    setOpenModalCreatePost(state);
  };
  if (isIE)
    return (
      <div className="h-screen bg-gray-50 flex overflow-hidden">
        <figure className="bg-gray-400 text-white font-bold rounded-lg border shadow-lg p-10 w-full">
          <img className="w-32 md:w-48 md:h-auto md:rounded-none mx-auto" src={browsie} alt="" width="384" height="512" />
          <div className="pt-6 md:p-8 text-center md:text-left space-y-4">
            <blockquote>
              <p className="text-lg font-semibold text-center">Lo sentimos... Actualmente estas usando un Navegador no compatible, te recomendamos usar Chrome o Edge.</p>
            </blockquote>
            <figcaption className="font-medium text-center">
              <div className="text-cyan-600">Gracias</div>
              <div className="text-gray-500">Staff Estrategia Digital</div>
            </figcaption>
          </div>
        </figure>
      </div>
    );
  return (
    <div className="h-full">
      {!user ? (
        <Login setProfile={handleSetProfile}></Login>
      ) : (
        <div className="h-screen bg-gray-50 flex overflow-hidden">
          <SideMenu profile={profile} mobileMenuOpen={mobileMenuOpen} setMobileMenuOpen={handleOpenMobileMenu} />

          {/* Content area */}
          <div className="flex-1 flex flex-col overflow-hidden">
            <TopMenu profile={profile} activities={activities} setModalCreatePostOpen={handleStatusModalCreatePost} setMobileMenuOpen={handleOpenMobileMenu} openFloatingVideo={openFloatingVideo} setOpenFloatingVideo={setOpenFloatingVideo} isRoot={location.pathname === "/" ? true : false} />

            {/* Main content */}
            <Switch>
              <Route path="/home" render={(props) => <Home {...props} user={user} profile={profile} activities={activities} createdPost={createdPost} setCreatedPost={setCreatedPost} />} />
              <Route path="/live" render={(props) => <Live {...props} user={user} profile={profile} onClick={setOpenFloatingVideo(false)} />} />
              <Route path="/agenda" render={(props) => <Agenda {...props} user={user} profile={profile} />} />
              <Route path="/speakers" component={Speakers} />
              <Route path="/missions" render={(props) => <Missions {...props} user={user} profile={profile} />} />
              <Route path="/sponsors" component={Sponsors} />
              <Route path="/stands" render={(props) => <Stands {...props} profile={profile} />} />
              <Route path="/questions" render={(props) => <Questions {...props} user={user} profile={profile} />} />
              <Route path="/documents" component={Documents} />
              <Route path="/help" component={Help} />

              {/* Out of menu */}
              <Route path="/profile" render={(props) => <Profile {...props} user={user} profile={profile} setProfile={setProfile} />} />

              {/* Internal */}
              <Route path="/chat" render={(props) => <Chat {...props} user={user} profile={profile} onClick={setOpenFloatingVideo(false)} />} />

              {/* Defualt */}
              <Route path="/" render={(props) => <Home {...props} user={user} profile={profile} activities={activities} createdPost={createdPost} setCreatedPost={setCreatedPost} />} />
            </Switch>
          </div>

          {/* Modal PIP Vimeo */}
          {FEATURES.streaming && <FloatingVideo profile={profile} open={openFloatingVideo} setOpen={setOpenFloatingVideo} />}

          {/* Modal Create Post */}
          <CreatePost profile={profile} open={openModalCreatePost} setOpen={handleStatusModalCreatePost} setCreatedPost={setCreatedPost} />
        </div>
      )}
    </div>
  );
}

export default App;
