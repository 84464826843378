import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router } from "react-router-dom";
import FirebaseProvider from "./utils/firebase";

ReactDOM.render(
  <Router>
    <FirebaseProvider>
      <App />
    </FirebaseProvider>
  </Router>,
  document.getElementById("root"),
);
reportWebVitals();
