//Libraries
import React, { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { MenuAlt2Icon } from "@heroicons/react/outline";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { InformationCircleIcon, UserIcon, StatusOnlineIcon, ChatAlt2Icon } from "@heroicons/react/solid";
import { BellIcon, VideoCameraIcon } from "@heroicons/react/outline";
import TimeAgo from "react-timeago";

//Services
import { logout } from "../../services/firebaseService";
import { classNames } from "../../services/tailwindHelpers";
import { Link } from "react-router-dom";

//Config
import config from "../../config";

//Styles
import user_placeholder from "../../assets/img/default-user-icon.jpg";

const TopMenu = ({ profile, activities = null, setModalCreatePostOpen, setMobileMenuOpen, openFloatingVideo, setOpenFloatingVideo, isRoot = false }) => {
  const userNavigation = [];

  const handleLogout = () => {
    logout();
  };

  return (
    <>
      {isRoot && (
        <div className="sticky top-0 bg-red-600 text-white text-md font-sans font-semibold lg:hidden">
          <div className="px-2 my-5 text-center ">RAAT 2024</div>
        </div>
      )}
      <div className={classNames(isRoot ? "top-16 lg:top-0" : "top-0", "sticky z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-2 shadow-sm")}>
        <button type="button" className="border-r border-gray-200 px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-red-500 md:hidden" onClick={() => setMobileMenuOpen(true)}>
          <span className="sr-only">Open sidebar</span>
          <MenuAlt2Icon className="h-6 w-6" aria-hidden="true" />
        </button>
        <div className="flex-1 flex justify-between px-4 sm:px-6">
          <div className="flex-1 flex"></div>
          <div className="ml-2 flex items-center sm:ml-6">
            <button type="button" className="flex bg-blue-500 mx-2 py-1 px-4 rounded-full items-center justify-center text-white font-semibold text-sm hover:bg-blue-600" onClick={() => setModalCreatePostOpen(true)}>
              Publicar
              <span className="sr-only">Add file</span>
            </button>

            {config && config.FEATURES.streaming && (
              <button type="button" className="py-1 px-2 rounded-full" onClick={() => setOpenFloatingVideo(!openFloatingVideo)}>
                <VideoCameraIcon className="h-6 w-6 text-gray-600 hover:text-blue-600" aria-hidden="true" />
                <span className="sr-only">Add file</span>
              </button>
            )}

            {/* Alerts dropdown */}
            <Menu as="div" className="relative px-2 flex-shrink-0">
              {({ open }) => (
                <>
                  <div>
                    <Menu.Button className="max-w-xs bg-white rounded-full flex items-center text-sm">
                      <BellIcon className="h-6 w-6 text-gray-600 hover:text-blue-600" aria-hidden="true" />
                    </Menu.Button>
                  </div>
                  <Transition
                    show={open}
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items static className="py-2 px-4 origin-top-right absolute right-0 mt-2 w-80 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                      {activities &&
                        activities.docs.map((activity) => (
                          <Menu.Item key={activity.id}>
                            {/* {activity.data().content}*/}

                            <div className="relative py-2">
                              <div className="relative flex space-x-3">
                                <div>
                                  <ActivityIcon type={activity.data().type}></ActivityIcon>
                                </div>
                                <div className="min-w-0 flex-1 flex justify-between space-x-4">
                                  <div>
                                    <p className="text-sm text-gray-600">
                                      {activity.data().content}{" "}
                                      <a href={activity.data().href} className="font-medium text-gray-900">
                                        {activity.data().target}
                                      </a>
                                    </p>
                                    <div className="mt-1 text-left text-xs whitespace-nowrap text-gray-400">{activity.data().date && activity.data().date.seconds ? <TimeAgo date={new Date(activity.data().date.seconds * 1000)} /> : null}</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Menu.Item>
                        ))}
                    </Menu.Items>
                  </Transition>
                </>
              )}
            </Menu>

            {/* Profile dropdown */}
            <Menu as="div" className="relative ml-2 flex-shrink-0">
              {({ open }) => (
                <div>
                  <div>
                    <Menu.Button className="max-w-xs bg-white rounded-full flex items-center text-sm focus:outline-none lg:p-2 lg:rounded-md lg:hover:bg-gray-50">
                      <img className="h-8 w-8 rounded-full object-cover" src={profile.url_photo || user_placeholder} alt="" />
                      <span className="hidden ml-3 text-gray-700 text-sm font-medium lg:block">
                        <span className="sr-only">Open user menu for </span>
                        {profile.name}
                      </span>
                      <ChevronDownIcon className="hidden flex-shrink-0 ml-1 h-5 w-5 text-gray-400 lg:block" aria-hidden="true" />
                    </Menu.Button>
                  </div>
                  <Transition
                    show={open}
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items static className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                      {userNavigation.map((item) => (
                        <Menu.Item key={item.name}>{({ active }) => <div className={classNames(active ? "bg-gray-100" : "", "block px-4 py-2 text-sm text-gray-700 cursor-pointer")}>{item.name}</div>}</Menu.Item>
                      ))}
                      <Menu.Item key="Profile">
                        <Link to="/profile" className="block px-4 py-2 text-sm text-gray-700 cursor-pointer hover:bg-gray-100">
                          Perfil
                        </Link>
                      </Menu.Item>
                      <Menu.Item key="Cerrar Sesión">
                        <div href="" onClick={handleLogout} className="block px-4 py-2 text-sm text-gray-700 cursor-pointer hover:bg-gray-100">
                          Cerrar Sesión
                        </div>
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </div>
              )}
            </Menu>
          </div>
        </div>
      </div>
    </>
  );
};

const ActivityIcon = ({ type }) => {
  switch (type) {
    case "informative":
      return (
        <span className="bg-blue-500 h-5 w-5 rounded-full flex items-center justify-center ring-8 ring-white">
          <InformationCircleIcon className="h-4 w-4 text-white" aria-hidden="true" />
        </span>
      );

    case "live":
      return (
        <span className="bg-red-500 h-5 w-5 rounded-full flex items-center justify-center ring-8 ring-white">
          <StatusOnlineIcon className="h-4 w-4 text-white" aria-hidden="true" />
        </span>
      );

    case "faqs":
      return (
        <span className="bg-green-500 h-5 w-5 rounded-full flex items-center justify-center ring-8 ring-white">
          <ChatAlt2Icon className="h-4 w-4 text-white" aria-hidden="true" />
        </span>
      );

    case "user":
      return (
        <span className="bg-gray-400 h-5 w-5 rounded-full flex items-center justify-center ring-8 ring-white">
          <UserIcon className="h-4 w-4 text-white" aria-hidden="true" />
        </span>
      );

    default:
      return (
        <span className="bg-blue-400 h-5 w-5 rounded-full flex items-center justify-center ring-8 ring-white">
          <InformationCircleIcon className="h-4 w-4 text-white" aria-hidden="true" />
        </span>
      );
  }
};

export default TopMenu;
