//Libraries
import React, { useState, useEffect } from "react";
import { ClockIcon, UsersIcon, StarIcon } from "@heroicons/react/solid";
import dayjs from "dayjs";

//Services
import ReviewConference from "./Modals/ReviewConference";
import { classNames } from "../services/tailwindHelpers";
import { getSchedule } from "../services/agendaService";

//Config
import tabs_schedule from "../assets/json/Agenda/raat/tabs.json";
import config from "../config";

//Const
const FIRST_DAY_EVENT = config.EVENT[config.TENANT].start_date;

const Agenda = ({ user, profile }) => {
  const [tabs, setTabs] = useState(tabs_schedule);
  const [events, setEvents] = useState([]);
  const [userId, setUserId] = useState();
  const [openModalCreatePost, SetOpenModalCreatePost] = useState(false);
  const [conferenceId, setConferenceId] = useState();
  const [title, setTitle] = useState();
  const [reviewSent, setReviewSent] = useState(false);
  const [selectedDate, setSelectedDate] = useState("");

  const currentDate = new Date();
  const formatCurrentDate = currentDate.toISOString("").split("T")[0].replaceAll("-", "");

  useEffect(() => {
    async function fetchData() {
      let date = dayToShow(formatCurrentDate, selectedDate);
      let current_tabs = [...tabs];

      const get_events = await getSchedule(date, user.uid, user.za);

      current_tabs = current_tabs.map((tab, tabIdx) => {
        tab.current = tab.date === formatCurrentDate ? true : false;

        if (tabIdx === 0 && formatCurrentDate < tab.date) {
          tab.current = true;
        }

        return tab;
      });

      setTabs(current_tabs);

      setEvents(get_events && get_events.length > 0 ? get_events : []);
      setUserId(user.uid);
    }
    fetchData();
    setReviewSent(false);
  }, [reviewSent, formatCurrentDate]);

  const handleChangeDay = async (type, event) => {
    let selected_day = type === "full" ? event : event.target.value;
    let current_tabs = [...tabs];

    const get_events = await getSchedule(selected_day, userId, user.za);

    setSelectedDate(selected_day);
    setEvents(get_events && get_events.length > 0 ? get_events : []);

    current_tabs = current_tabs.map((tab) => {
      tab.current = tab.date === selected_day ? true : false;

      return tab;
    });

    setTabs(current_tabs);
  };

  const handleStatusModalCreatePost = (state, conferenceId, title) => {
    SetOpenModalCreatePost(state);
    setConferenceId(conferenceId);
    setTitle(title);
  };

  const handleReviewSent = () => {
    setReviewSent(true);
  };

  return (
    <main className="flex-1 overflow-y-auto">
      <div className="pt-8 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex">
          <h1 className="my-4 flex-1 text-2xl font-bold text-gray-600">Agenda</h1>
        </div>
        <div className="sm:hidden">
          <label htmlFor="tabs" className="sr-only">
            Select a tab
          </label>
          <select id="tabs" name="tabs" onChange={(event) => handleChangeDay("mobile", event)} className="block h-12 w-full focus:ring-yellow-500 focus:border-yellow-500 border-gray-300 rounded-md" defaultValue={formatCurrentDate}>
            {tabs.map((tab) => (
              <option key={tab.date} value={tab.date}>
                {tab.name} {tab.subname ? `| ${tab.subname}` : ""}
              </option>
            ))}
          </select>
        </div>
        <div className="hidden sm:block">
          <nav className="relative z-0 rounded-lg shadow flex divide-x divide-gray-200" aria-label="Tabs">
            {tabs.map((tab, tabIdx) => {
              return (
                <a
                  key={tab.date}
                  onClick={() => handleChangeDay("full", tab.date)}
                  className={classNames(tab.current ? "text-gray-900" : "text-gray-500 hover:text-gray-700", tabIdx === 0 ? "rounded-l-lg" : "", "group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-sm font-medium text-center cursor-pointer hover:bg-gray-100 focus:z-10")}
                  aria-current={tab.current ? "page" : undefined}
                >
                  <span>{tab.name}</span>
                  <p className="text-xs text-gray-400">{tab.subname}</p>
                  <span aria-hidden="true" className={classNames(tab.current ? "bg-yellow-500" : "bg-transparent", "absolute inset-x-0 bottom-0 h-0.5")} />
                </a>
              );
            })}
          </nav>
        </div>

        {/* Events */}
        <div className="my-4">
          <div className="bg-white shadow overflow-hidden sm:rounded-md">
            <ul className="divide-y divide-gray-200">
              {events.length > 0 ? (
                events.map((event) => {
                  return (
                    <li key={event.id}>
                      <a className="block hover:bg-gray-50">
                        <div className="px-4 py-4 sm:px-6">
                          <div className="flex items-center justify-between">
                            {!event.company || event.company === "" ? (
                              <div className="flex items-center justify-between">
                                <p className="text-sm font-medium text-red-600 break-words">{event.title}</p>
                                <div className="ml-2 flex-shrink-0 flex">
                                  {event.survey && !event.answered ? (
                                    <div title="Evalúa la conferencia" className="flex bg-blue-500 mx-2 py-0.5 px-2.5 rounded-full items-center justify-center text-white font-semibold text-xs hover:bg-blue-600 cursor-pointer" onClick={() => handleStatusModalCreatePost(true, event.id, event.title)}>
                                      Evaluar
                                    </div>
                                  ) : event.survey && event.answered ? (
                                    <StarIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-yellow-400 fill" aria-hidden="true" />
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            ) : (
                              <div className="flex justify-between items-stretch">
                                <p className="text-sm font-bold text-red-600">{event.company}</p>&nbsp;
                                <p className="text-sm font-medium text-red-600 break-words">- {event.title}</p>
                                <div className="ml-2 flex-shrink-0 flex">
                                  {event.survey && !event.answered ? (
                                    <div title="Evalúa la conferencia" className="flex items-center justify-between cursor-pointer" onClick={() => handleStatusModalCreatePost(true, event.id, event.title)}>
                                      <StarIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                                    </div>
                                  ) : event.survey && event.answered ? (
                                    <StarIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-yellow-400" aria-hidden="true" />
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            )}
                            <div className="ml-2 flex-shrink-0 flex justify-end">
                              <p className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                {event.startDate && event.endDate && Math.ceil(dayjs.unix(event.endDate._seconds).diff(dayjs.unix(event.startDate._seconds), "minute", true))}
                                min
                              </p>
                            </div>
                          </div>
                          <div className="mt-2 sm:flex sm:justify-between">
                            <div className="sm:flex">
                              <p className="flex items-center text-sm text-gray-500">
                                <UsersIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                                {event.speakers}
                              </p>
                            </div>
                            <div className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
                              <ClockIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                              <p>
                                {event.startDate && dayjs.unix(event.startDate._seconds).format("HH:mm")} - {event.endDate && dayjs.unix(event.endDate._seconds).format("HH:mm")}
                              </p>
                            </div>
                          </div>
                        </div>
                      </a>
                    </li>
                  );
                })
              ) : (
                <div className="p-12">
                  <img className="m-auto h-96" src="img/undraw_not_events.svg"></img>
                  <p className="text-center text-2xl">No existen eventos para este día</p>
                </div>
              )}
            </ul>
          </div>
        </div>
      </div>

      {/* Modals */}
      <ReviewConference profile={profile} open={openModalCreatePost} setOpen={handleStatusModalCreatePost} conferenceId={conferenceId} setReviewSent={handleReviewSent} title={title} />
    </main>
  );
};

function dayToShow(formatCurrentDate, selectedDate) {
  var date = selectedDate !== "" ? selectedDate : formatCurrentDate;

  if (date < FIRST_DAY_EVENT && selectedDate === "") {
    date = FIRST_DAY_EVENT;
  }

  return date;
}

// function currentTab(date, formatCurrentDate, selectedDate) {
//   var current = false;

//   if (date === formatCurrentDate && selectedDate === "") {
//     current = true;
//   } else if (date === selectedDate && selectedDate !== "") {
//     current = true;
//   } else if (formatCurrentDate < FIRST_DAY_EVENT && selectedDate === "") {
//     current = true;
//   }

//   return current;
// }

export default Agenda;
