//Libraries
import React, { Fragment, useState, useEffect, useContext, useCallback, useRef } from "react";
import { Menu, Transition } from "@headlessui/react";
import { InformationCircleIcon, FlagIcon, TrashIcon, DotsVerticalIcon, UserIcon, StatusOnlineIcon, ChatAlt2Icon } from "@heroicons/react/solid";
import TimeAgo from "react-timeago";

//Components
import PostComments from "./Panels/PostComments";
import Likes from "./Modals/Likes";

//Services
import { classNames } from "../services/tailwindHelpers";
import { updatePost, deletePost, reportPost } from "../services/firebaseService";
import { FirebaseContext } from "../utils/firebase";

//Styles
import defaultUserIcon from "../assets/img/default-user-icon.jpg";

const Home = ({ profile, user, activities, createdPost, setCreatedPost }) => {
  const [posts, setPosts] = useState([]);
  const [lastDoc, setLastDoc] = useState();
  const [loading, setLoading] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);
  const observer = useRef();

  const lastPostElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && !isEmpty) {
          fetchMorePosts();
        }
      });

      if (node) observer.current.observe(node);
    },
    [loading, isEmpty],
  );

  const firebase = useContext(FirebaseContext);

  useEffect(() => {
    setLoading(true);
    firebase
      .firestore()
      .collection("posts")
      .orderBy("date", "desc")
      .limit(10)
      .get()
      .then((collections) => {
        updateState(collections);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  useEffect(() => {
    if (!createdPost) return;

    let current_posts = [...posts];

    current_posts.unshift(createdPost);

    setPosts(current_posts);
    setCreatedPost(null);
  }, [createdPost]);

  // const [posts, postLoading] = useCollection(firebase.firestore().collection("posts").orderBy("date", "desc"), FIRESTORE_CONFIG.getCollection);
  //const [activities] = useCollection(firebase.firestore().collection("activity").orderBy("date", "desc"), FIRESTORE_CONFIG.getCollection);

  const [activePostComments, setActivePostComments] = useState([]);
  const [postComments, setPostComments] = useState([]);

  const [openPanelComments, setOpenPanelComments] = useState(false);

  const handleLike = async (post_id, post_likes) => {
    if (!profile) return;

    let current_posts_likes = [...post_likes];
    let current_posts = [...posts];

    const index = current_posts_likes.findIndex((user) => user.uid === profile.uid);

    if (index >= 0) {
      current_posts_likes.splice(index, 1);
    } else {
      current_posts_likes.push({
        uid: profile.uid,
        fullname: `${profile.name} ${profile.last}`,
      });
    }

    current_posts = current_posts.map((post) => {
      if (post.id === post_id) {
        post.likes = current_posts_likes;
      }

      return post;
    });

    setPosts(current_posts);

    await updatePost(post_id, current_posts_likes);
  };

  const handleOpenComments = async (post_id, post_comments) => {
    setActivePostComments(post_id);
    setPostComments(post_comments);
    setOpenPanelComments(true);
  };

  const updateState = (collections) => {
    const isCollectionEmpty = collections.size === 0;
    if (!isCollectionEmpty) {
      const morePosts = collections.docs.map((post) => {
        return { id: post.id, ...post.data() };
      });

      const lastDoc = collections.docs[collections.docs.length - 1];

      setPosts((prevPosts) => [...prevPosts, ...morePosts]);
      setLastDoc(lastDoc);
    } else {
      setIsEmpty(true);
    }
    setLoading(false);
  };

  const fetchMorePosts = () => {
    setLoading(true);
    firebase
      .firestore()
      .collection("posts")
      .orderBy("date", "desc")
      .limit(10)
      .startAfter(lastDoc)
      .get()
      .then((collections) => {
        updateState(collections);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleDeletePost = async (post_id, post_user_id) => {
    if (!profile) return;
    if (!post_id) return;
    if (profile.uid !== post_user_id) return;

    let current_posts = [...posts];

    const index = current_posts.findIndex((post) => post.id === post_id);

    if (index >= 0) {
      current_posts.splice(index, 1);
    }

    await deletePost(post_id);

    setPosts(current_posts);
  };

  const handleReportPost = async (post_id) => {
    if (!profile) return;
    if (!post_id) return;

    await reportPost(post_id);
  };

  return (
    <div className="flex-1 flex items-stretch overflow-hidden">
      <main className="flex-1 overflow-y-auto">
        <div className="pt-8 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          {/* Wall */}
          <section className="mt-4 pb-16">
            <ul className="p-2 space-y-4">
              {loading && <PostLoading />}
              {posts &&
                posts.map((post, index) => {    
                  if(!post.user) return;
                  
                  if (posts.length === index + 1) {
                    return (
                      <li ref={lastPostElementRef} key={post.id} className="bg-white px-4 py-6 shadow sm:p-6 sm:rounded-lg">
                        <article aria-labelledby="question-title-81614">
                          <div>
                            <div className="flex space-x-3">
                              <div className="flex-shrink-0">
                                <img className="h-10 w-10 rounded-full object-cover" src={(post.user && post.user.url_photo) || defaultUserIcon} alt="" />
                              </div>
                              <div className="min-w-0 flex-1">
                                <div className="text-sm font-medium text-gray-900">
                                  <p className="">{post.user && post.user.fullname}</p>
                                </div>
                                <p className="text-sm text-gray-500">{post.date ? <TimeAgo date={new Date(post.date.seconds * 1000)} /> : <TimeAgo date={Date.now()} />}</p>
                              </div>
                              <div className="flex-shrink-0 self-center flex">
                                <Menu as="div" className="relative inline-block text-left">
                                  {({ open }) => (
                                    <>
                                      <div>
                                        <Menu.Button className="-m-2 p-2 rounded-full flex items-center text-gray-400 hover:text-gray-600">
                                          <span className="sr-only">Open options</span>
                                          <DotsVerticalIcon className="h-5 w-5" aria-hidden="true" />
                                        </Menu.Button>
                                      </div>

                                      <Transition
                                        show={open}
                                        as={Fragment}
                                        enter="transition ease-out duration-100"
                                        enterFrom="transform opacity-0 scale-95"
                                        enterTo="transform opacity-100 scale-100"
                                        leave="transition ease-in duration-75"
                                        leaveFrom="transform opacity-100 scale-100"
                                        leaveTo="transform opacity-0 scale-95"
                                      >
                                        <Menu.Items static className="origin-top-right absolute right-0 mt-2 w-40 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                          <div className="py-1">
                                            <Menu.Item>
                                              {({ active }) =>
                                                post.user && profile.uid === post.user.uid ? (
                                                  <div onClick={() => handleDeletePost(post.id, post.user && post.user.uid)} className={classNames(active ? "bg-gray-100 text-gray-900" : "text-gray-700", "flex px-4 py-2 text-sm")}>
                                                    <TrashIcon className="mr-3 h-5 w-5 text-gray-400" aria-hidden="true" />
                                                    <span>Eliminar</span>
                                                  </div>
                                                ) : (
                                                  <Fragment></Fragment>
                                                )
                                              }
                                            </Menu.Item>
                                            <Menu.Item>
                                              {({ active }) => (
                                                <div onClick={() => handleReportPost(post.id)} className={classNames(active ? "bg-gray-100 text-gray-900" : "text-gray-700", "flex px-4 py-2 text-sm")}>
                                                  <FlagIcon className="mr-3 h-5 w-5 text-gray-400" aria-hidden="true" />
                                                  <span>Reportar</span>
                                                </div>
                                              )}
                                            </Menu.Item>
                                          </div>
                                        </Menu.Items>
                                      </Transition>
                                    </>
                                  )}
                                </Menu>
                              </div>
                            </div>
                          </div>
                          <div className="mt-2 text-sm text-gray-700 space-y-4">
                            <p>{post.content}</p>
                          </div>
                          <div className="mt-2 text-sm text-gray-700 space-y-4">
                            <img className="mx-auto" src={post.images && post.images.length > 0 && post.images[0]}></img>
                          </div>
                          <div className="mt-6 flex justify-between space-x-8">
                            <div className="flex space-x-2">
                              <span className="inline-flex items-center text-sm">
                                <button className="inline-flex space-x-2 text-gray-400 hover:text-gray-500 focus:outline-none" onClick={() => handleLike(post.id, post.likes)} id="">
                                  <svg className={classNames(user && profile && post.likes.findIndex((user) => user && user.uid === profile.uid) >= 0 ? "text-blue-500" : "text-gray-400", "h-5 w-5")} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path d="M2 10.5a1.5 1.5 0 113 0v6a1.5 1.5 0 01-3 0v-6zM6 10.333v5.43a2 2 0 001.106 1.79l.05.025A4 4 0 008.943 18h5.416a2 2 0 001.962-1.608l1.2-6A2 2 0 0015.56 8H12V4a2 2 0 00-2-2 1 1 0 00-1 1v.667a4 4 0 01-.8 2.4L6.8 7.933a4 4 0 00-.8 2.4z" />
                                  </svg>
                                </button>
                                <Likes users={post.likes} />
                                <span className="sr-only">likes</span>
                              </span>
                              <span className="inline-flex items-center text-sm">
                                <button className="inline-flex space-x-2 text-gray-400 hover:text-gray-500 focus:outline-none" onClick={() => handleOpenComments(post.id, post.comments)}>
                                  <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fillRule="evenodd" d="M18 5v8a2 2 0 01-2 2h-5l-5 4v-4H4a2 2 0 01-2-2V5a2 2 0 012-2h12a2 2 0 012 2zM7 8H5v2h2V8zm2 0h2v2H9V8zm6 0h-2v2h2V8z" clipRule="evenodd" />
                                  </svg>
                                </button>
                                <span className="ml-2 font-medium text-gray-900">{post.comments ? post.comments.length : 0}</span>
                                <span className="sr-only">replies</span>
                              </span>
                            </div>
                            <div className="flex text-sm">
                              <span className="inline-flex items-center text-sm"></span>
                            </div>
                          </div>
                        </article>
                      </li>
                    );
                  } else {
                    return (
                      <li key={post.id} className="bg-white px-4 py-6 shadow sm:p-6 sm:rounded-lg">
                        <article aria-labelledby="question-title-81614">
                          <div>
                            <div className="flex space-x-3">
                              <div className="flex-shrink-0">
                                <img className="h-10 w-10 rounded-full object-cover" src={(post.user && post.user.url_photo) || defaultUserIcon} alt="" />
                              </div>
                              <div className="min-w-0 flex-1">
                                <div className="text-sm font-medium text-gray-900">
                                  <p className="">{post.user && post.user.fullname}</p>
                                </div>
                                <p className="text-sm text-gray-500">{post.date ? <TimeAgo date={new Date(post.date.seconds * 1000)} /> : <TimeAgo date={Date.now()} />}</p>
                              </div>
                              <div className="flex-shrink-0 self-center flex">
                                <Menu as="div" className="relative inline-block text-left">
                                  {({ open }) => (
                                    <>
                                      <div>
                                        <Menu.Button className="-m-2 p-2 rounded-full flex items-center text-gray-400 hover:text-gray-600">
                                          <span className="sr-only">Open options</span>
                                          <DotsVerticalIcon className="h-5 w-5" aria-hidden="true" />
                                        </Menu.Button>
                                      </div>

                                      <Transition
                                        show={open}
                                        as={Fragment}
                                        enter="transition ease-out duration-100"
                                        enterFrom="transform opacity-0 scale-95"
                                        enterTo="transform opacity-100 scale-100"
                                        leave="transition ease-in duration-75"
                                        leaveFrom="transform opacity-100 scale-100"
                                        leaveTo="transform opacity-0 scale-95"
                                      >
                                        <Menu.Items static className="origin-top-right absolute right-0 mt-2 w-40 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                          <div className="py-1">
                                            <Menu.Item>
                                              {({ active }) =>
                                                post.user && profile.uid === post.user.uid ? (
                                                  <div onClick={() => handleDeletePost(post.id, post.user && post.user.uid)} className={classNames(active ? "bg-gray-100 text-gray-900" : "text-gray-700", "flex px-4 py-2 text-sm cursor-pointer")}>
                                                    <TrashIcon className="mr-3 h-5 w-5 text-gray-400" aria-hidden="true" />
                                                    <span>Eliminar</span>
                                                  </div>
                                                ) : (
                                                  <Fragment></Fragment>
                                                )
                                              }
                                            </Menu.Item>
                                            <Menu.Item>
                                              {({ active }) => (
                                                <div onClick={() => handleReportPost(post.id)} className={classNames(active ? "bg-gray-100 text-gray-900" : "text-gray-700", "flex px-4 py-2 text-sm cursor-pointer")}>
                                                  <FlagIcon className="mr-3 h-5 w-5 text-gray-400" aria-hidden="true" />
                                                  <span>Reportar</span>
                                                </div>
                                              )}
                                            </Menu.Item>
                                          </div>
                                        </Menu.Items>
                                      </Transition>
                                    </>
                                  )}
                                </Menu>
                              </div>
                            </div>
                          </div>
                          <div className="mt-2 text-sm text-gray-700 space-y-4">
                            <p>{post.content}</p>
                          </div>
                          <div className="mt-2 text-sm text-gray-700 space-y-4">
                            <img className="mx-auto" src={post.images && post.images.length > 0 && post.images[0]}></img>
                          </div>
                          <div className="mt-6 flex justify-between space-x-8">
                            <div className="flex space-x-2">
                              <span className="inline-flex items-center text-sm">
                                <button className="inline-flex space-x-2 text-gray-400 hover:text-gray-500 focus:outline-none" onClick={() => handleLike(post.id, post.likes)} id="">
                                  <svg className={classNames(user && profile && post.likes.findIndex((user) => user && user.uid === profile.uid) >= 0 ? "text-blue-500" : "text-gray-400", "h-5 w-5")} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path d="M2 10.5a1.5 1.5 0 113 0v6a1.5 1.5 0 01-3 0v-6zM6 10.333v5.43a2 2 0 001.106 1.79l.05.025A4 4 0 008.943 18h5.416a2 2 0 001.962-1.608l1.2-6A2 2 0 0015.56 8H12V4a2 2 0 00-2-2 1 1 0 00-1 1v.667a4 4 0 01-.8 2.4L6.8 7.933a4 4 0 00-.8 2.4z" />
                                  </svg>
                                </button>
                                <Likes users={post.likes} />
                                <span className="sr-only">likes</span>
                              </span>
                              <span className="inline-flex items-center text-sm">
                                <button className="inline-flex space-x-2 text-gray-400 hover:text-gray-500 focus:outline-none" onClick={() => handleOpenComments(post.id, post.comments)}>
                                  <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fillRule="evenodd" d="M18 5v8a2 2 0 01-2 2h-5l-5 4v-4H4a2 2 0 01-2-2V5a2 2 0 012-2h12a2 2 0 012 2zM7 8H5v2h2V8zm2 0h2v2H9V8zm6 0h-2v2h2V8z" clipRule="evenodd" />
                                  </svg>
                                </button>
                                <span className="ml-2 font-medium text-gray-900">{post.comments ? post.comments.length : 0}</span>
                                <span className="sr-only">replies</span>
                              </span>
                            </div>
                            <div className="flex text-sm">
                              <span className="inline-flex items-center text-sm"></span>
                            </div>
                          </div>
                        </article>
                      </li>
                    );
                  }
                })}
            </ul>
          </section>
        </div>
      </main>

      {/* Details sidebar */}
      <aside className="hidden w-96 bg-white p-8 border-l border-gray-200 overflow-y-auto lg:block">
        <div className="text-lg font-bold pb-8 space-y-6">Actividades</div>
        <div>
          <div className="flow-root">
            <ul className="-mb-8">
              {activities &&
                activities.docs.map((activity) => (
                  <li key={activity.id}>
                    <div className="relative pb-8">
                      <div className="relative flex space-x-3">
                        <div>
                          <ActivityIcon type={activity.data().type}></ActivityIcon>
                        </div>
                        <div className="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
                          <div>
                            <p className="text-sm text-gray-500">
                              {activity.data().content}{" "}
                              <a href={activity.data().href} className="font-medium text-gray-900">
                                {activity.data().target}
                              </a>
                            </p>
                          </div>
                          <div className="text-right text-sm whitespace-nowrap text-gray-500">{activity.data().date && activity.data().date.seconds ? <TimeAgo date={new Date(activity.data().date.seconds * 1000)} /> : null}</div>
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
            </ul>
          </div>
        </div>
      </aside>

      {/* Post Comments */}
      <PostComments profile={profile} postId={activePostComments} comments={postComments} open={openPanelComments} setOpen={setOpenPanelComments} />
    </div>
  );
};

const PostLoading = () => {
  return (
    <Fragment>
      <li className="bg-white px-4 py-6 shadow sm:p-6 sm:rounded-lg">
        <div className="p-4 w-full mx-auto">
          <div className="animate-pulse">
            <div className=" flex space-x-4">
              <div className="rounded-full bg-gray-400 h-12 w-12"></div>
              <div className="flex-1 space-y-3">
                <div className="h-4 bg-gray-400 rounded w-1/2"></div>
                <div className="h-4 bg-gray-400 rounded w-1/3"></div>
              </div>
            </div>
            <div className="mt-4">
              <div className="h-8 bg-gray-400 rounded w-full"></div>
            </div>
            <div className="mt-4">
              <div className="h-40 bg-gray-400 rounded w-full"></div>
            </div>
          </div>
        </div>
      </li>
      <li className="bg-white px-4 py-6 shadow sm:p-6 sm:rounded-lg">
        <div className="p-4 w-full mx-auto">
          <div className="animate-pulse">
            <div className=" flex space-x-4">
              <div className="rounded-full bg-gray-400 h-12 w-12"></div>
              <div className="flex-1 space-y-3">
                <div className="h-4 bg-gray-400 rounded w-1/2"></div>
                <div className="h-4 bg-gray-400 rounded w-1/3"></div>
              </div>
            </div>
            <div className="mt-4">
              <div className="h-8 bg-gray-400 rounded w-full"></div>
            </div>
            <div className="mt-4">
              <div className="h-40 bg-gray-400 rounded w-full"></div>
            </div>
          </div>
        </div>
      </li>
    </Fragment>
  );
};

const ActivityIcon = ({ type }) => {
  switch (type) {
    case "informative":
      return (
        <span className="bg-blue-500 h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white">
          <InformationCircleIcon className="h-5 w-5 text-white" aria-hidden="true" />
        </span>
      );
      break;
    case "live":
      return (
        <span className="bg-red-500 h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white">
          <StatusOnlineIcon className="h-5 w-5 text-white" aria-hidden="true" />
        </span>
      );
      break;
    case "faqs":
      return (
        <span className="bg-green-500 h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white">
          <ChatAlt2Icon className="h-5 w-5 text-white" aria-hidden="true" />
        </span>
      );
      break;

    case "user":
      return (
        <span className="bg-gray-400 h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white">
          <UserIcon className="h-5 w-5 text-white" aria-hidden="true" />
        </span>
      );
      break;
    default:
      return (
        <span className="bg-blue-400 h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white">
          <InformationCircleIcon className="h-5 w-5 text-white" aria-hidden="true" />
        </span>
      );
      break;
  }
};

export default Home;
