//Libraries
import axios from "axios";

//Config
import { API_URL } from "../config.json";

export const uploadImagePosts = async (data, user_token = "") => {
  const config = {
    headers: { Authorization: `Bearer ${user_token}` },
  };

  let res = await axios.post(`${API_URL}/rati/posts/image`, data, config).catch((e) => {
    console.log("[postsService.js][uploadImagePosts] Error: ", e);

    return [];
  });

  if (res && res.status !== 200) return [];

  return res.data.url;
};
