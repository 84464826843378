//Libraries
import React, { useState } from "react";
import { Fragment, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { StarIcon } from "@heroicons/react/solid";

//Custom Hooks
import useInput from "../../hooks/use-input";

//Services
import { classNames } from "../../services/tailwindHelpers";
import { createReview } from "../../services/firebaseService";
import { completeChallenge } from "../../services/missionsService";

const ReviewConference = ({ profile, open, setOpen, conferenceId, setReviewSent, title }) => {
  const cancelButtonRef = useRef(null);
  const submitButtonRef = useRef(null);
  const textAreaRef = useRef();
  const [rating, setRating] = useState(null);
  const [hoverStar, setHoverStar] = useState(null);
  const [blockSubmitButton, setBlockSubmitButton] = useState(false);
  const [ratingError, setRatingError] = useState(false);

  const { value: message, isValid: messageIsValid, hasError: messageHasError, valueChangeHandler: messageChangeHandler, inputBlurHandler: messageBlurHandler, reset: messageReset } = useInput((value) => value.trim() !== "");

  const formSubmissionHandler = async (event) => {
    event.preventDefault();

    if (!messageIsValid || !rating) {
      messageBlurHandler();
      setRatingError(true);
      return;
    }
    if (submitButtonRef.current) submitButtonRef.current.setAttribute("disabled", "disabled");
    setBlockSubmitButton(true);

    const response = await createReview(conferenceId, title, rating, message, profile);

    if (!response) return;

    messageReset();
    setOpen(false);
    setBlockSubmitButton(false);
    setRating(null);
    setHoverStar(null);
    setReviewSent();

    //Send Challenge
    if (response) {
      await completeChallenge("-NTyoROwd2Awv7JO3zKK", profile.uid, profile.email);
    }
  };

  const clean = async () => {
    messageReset();
    setOpen(false);
    setRating(null);
    setHoverStar(null);
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" static className="fixed z-10 inset-0 overflow-y-auto" initialFocus={textAreaRef} open={open} onClose={clean}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <form className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all max-w-lg sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full sm:p-6" onSubmit={(event) => formSubmissionHandler(event)}>
              <h1 className="mb-8 flex-1 text-2xl font-bold text-gray-700">Evalúa la conferencia</h1>
              <h1 className="mb-8 flex-1 text-gray-700">{title}</h1>
              <div>
                <div className="mt-1">
                  <textarea
                    ref={textAreaRef}
                    id="message"
                    name="message"
                    rows={10}
                    className={classNames(messageHasError ? "border-red-300" : "border-gray-300", "appearance-none block w-full px-3 py-2 border rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-normal")}
                    value={message}
                    onChange={messageChangeHandler}
                    onBlur={messageBlurHandler}
                    placeholder="Escribe un comentario"
                  />
                </div>
                {messageHasError && (
                  <p className="mt-2 text-sm text-red-600" id="label-message">
                    Agrega un comentario a la calificación
                  </p>
                )}
                <div className="mt-4 w-full rounded-md shadow-sm">
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <div className="flex justify-center px-6 pt-5 pb-6">
                      <div className="space-y-1 text-center">
                        <div className="flex text-sm text-center text-gray-600">
                          {[...Array(5)].map((star, index) => {
                            const ratingValue = index + 1;
                            return (
                              <label key={ratingValue}>
                                <input
                                  type="radio"
                                  name="rating"
                                  value={ratingValue}
                                  onClick={() => {
                                    setRating(ratingValue);
                                    setRatingError(false);
                                  }}
                                />
                                <StarIcon color={ratingValue <= (hoverStar || rating) ? "#ffc107" : "#e4e5e9"} className="flex-shrink-0 mr-1.5 h-10 w-10 cursor-pointer" onMouseEnter={() => setHoverStar(ratingValue)} onMouseLeave={() => setHoverStar(null)} />
                              </label>
                            );
                          })}
                        </div>
                        {ratingError && (
                          <p className="mt-2 text-sm text-red-600" id="label-message">
                            Selecciona una calificación
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                <button
                  type="submit"
                  ref={submitButtonRef}
                  className={classNames(
                    blockSubmitButton
                      ? "opacity-50 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-500 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-300 sm:col-start-2 sm:text-sm"
                      : "w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-500 text-base font-medium text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-300 sm:col-start-2 sm:text-sm",
                  )}
                >
                  <div className="mr-4">{blockSubmitButton ? "Enviando" : "Calificar"}</div>
                  {blockSubmitButton ? (
                    <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                  ) : null}
                </button>
                <div
                  className="cursor-pointer mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-300 sm:mt-0 sm:col-start-1 sm:text-sm"
                  onClick={() => {
                    clean();
                  }}
                  ref={cancelButtonRef}
                >
                  Cancelar
                </div>
              </div>
            </form>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default ReviewConference;
