//Libraries
import { useRef, useState, useEffect } from "react";
import { CameraIcon } from "@heroicons/react/solid";

//Components
import Alert from "../shared/Alert";

//Services
import { sendPictureProfile } from "../../services/profileService";
import { completeChallenge } from "../../services/missionsService";

//Styles
import user_placeholder from "../../assets/img/default-user-icon.jpg";

const Information = ({ profile, setProfile }) => {
  const inputFileRef = useRef(null);

  const [profilePic, setProfilePic] = useState({ url: "", imageHash: "" });

  const [showSuccessAlert, setShowSuccessAlert] = useState(false);

  const handleChangeProfile = async () => {
    const file = inputFileRef.current.files && inputFileRef.current.files.length > 0 ? inputFileRef.current.files[0] : null;

    if (!file || (file && file.size > 10485760)) {
      return;
    }

    const data = new FormData();

    data.append("id_user_firebase", profile.uid);
    data.append("image", file);

    const response = await sendPictureProfile(data, profile.za);

    if (!response) {
      return;
    }

    setProfile({ ...profile, url_photo: "" });
    setTimeout(() => setProfile({ ...profile, url_photo: `${response}?${Date.now()}` }), 1000);

    setProfilePic({ url: "", imageHash: "" });
    setTimeout(() => setProfilePic({ url: response, imageHash: Date.now() }), 1000);

    setShowSuccessAlert(true);

    //Send Challenge
    if (response) {
      await completeChallenge("-NTyoROqzWmreoW2_hUe", profile.uid, profile.email);
    }
  };

  const triggerPictureInput = () => {
    inputFileRef.current.click();
  };

  useEffect(() => {
    setProfilePic({ url: profile.url_photo, imageHash: Date.now() });
  }, [profile]);

  return (
    <div className="pt-4">
      <div>
        <h3 className="text-lg leading-6 font-medium text-gray-900">Información de tu cuenta</h3>

        <div className="mt-8 inline-block relative cursor-pointer hover:opacity-70 ">
          <img className="h-20 w-20 rounded-full ring-4 ring-white sm:h-24 sm:w-24 object-cover" src={`${profilePic.url}?${profilePic.imageHash}` || user_placeholder} alt="" onClick={() => triggerPictureInput()} />
          <CameraIcon className="w-8 h-8 absolute transform text-white -translate-y-14 translate-x-6 sm:-translate-y-16 sm:translate-x-8" aria-hidden="true" onClick={() => triggerPictureInput()} />
          <input className="sr-only" type="file" ref={inputFileRef} onChange={() => handleChangeProfile()}></input>
        </div>

        {/* Success Alert */}
        <Alert show={showSuccessAlert} setShow={setShowSuccessAlert} type={"success"} title={"Foto guardada correctamente"} body={"Este cambio puede tomar hasta 5 minutos en verse reflejado."} />

        {/* Description list */}
        <div className="mt-6 max-w-5xl px-4">
          <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">Nombre</dt>
              <dd className="mt-1 text-sm text-gray-900">{profile.name}</dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">Apellido</dt>
              <dd className="mt-1 text-sm text-gray-900">{profile.last}</dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">Correo</dt>
              <dd className="mt-1 text-sm text-gray-900">{profile.email}</dd>
            </div>
          </dl>
        </div>
      </div>
    </div>
  );
};

export default Information;
