//Libraries
import React, { useEffect, useState } from "react";

//Components
import Iframe from "./Iframe";

//Services
import sites from "../assets/json/Missions/sites.json";

const Missions = ({ profile }) => {
  const [zoomHeight, setZoomHeight] = useState(500);

  const [selectedChannel] = useState(sites[0]);

  useEffect(() => {
    const new_height = document.getElementById("missions-div").clientHeight;

    setZoomHeight(new_height);
  }, []);

  return (
    <div className="flex-1 flex items-stretch overflow-hidden">
      <main id="missions-div" className="flex-1 h-100 overflow-y-auto">
        <Iframe profile={profile} height={zoomHeight} type={selectedChannel.type} url={selectedChannel.url} />
      </main>
    </div>
  );
};

export default Missions;
